import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlUnit)

export class UnitService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query UNIT_LIST($filter: [Filter]!, $pagination: PaginationInput!) {
          listUnits(filter: $filter, pagination: $pagination) {
            id
            code
            projectId
            unitTemplate {
              id
              title {
                en
                ar
              }
              project {
                id
                name {
                  en
                  ar
                }
              }
            }
            isRemoved
            owners
            members
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        filter,
        pagination
      }
    })
    return response.data.listUnits
  }

  static async create(unitInput) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation UNIT_CREATE($unitInput: UnitInput!) {
          createUnit(unitInput: $unitInput)
        }
      `,
      variables: {
        unitInput
      }
    })
    if (response.data.createUnit && response.data.createUnit.status) {
      return response.data.createUnit.result
    } else {
      throw response.data.createUnit.error
    }
  }

  static async remove(unitId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation UNIT_DESTROY($unitId: String!) {
          removeUnit(unitId: $unitId)
        }
      `,
      variables: {
        unitId
      }
    })
    if (response.data.removeUnit && response.data.removeUnit.status) {
      return response.data.removeUnit.result
    } else {
      console.error(response.data.removeUnit.error)
      throw response.data.removeUnit.error
    }
  }

  static async removeAll(unitsIds) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation UNIT_DESTROY($unitsIds: String!) {
          removeUnits(unitsIds: $unitsIds)
        }
      `,
      variables: {
        unitsIds
      }
    })
    if (response.data.removeUnits && response.data.removeUnits.status) {
      return response.data.removeUnits.result
    } else {
      console.error(response.data.removeUnits.error)
      throw response.data.removeUnits.error
    }
  }
  // static async update(unitTemplateId, unitTemplateUpdate) {
  //   const response = await graphqlClient.mutate({
  //     mutation: gql`
  //       mutation TEMPLATE_UPDATE(
  //         $unitTemplateId: String!
  //         $unitTemplateUpdate: UnitTemplateUpdate!
  //       ) {
  //         editUnitTemplate(
  //           unitTemplateId: $unitTemplateId
  //           unitTemplateUpdate: $unitTemplateUpdate
  //         )
  //       }
  //     `,
  //     variables: {
  //       unitTemplateId,
  //       unitTemplateUpdate
  //     }
  //   })
  //   if (
  //     response.data.editUnitTemplate &&
  //     response.data.editUnitTemplate.status
  //   ) {
  //     return response.data.editUnitTemplate.result
  //   } else {
  //     throw response.data.editUnitTemplate.error
  //   }
  // }

  static async find(unitId) {
    const response = await graphqlClient.query({
      query: gql`
        query UNIT_FIND($unitId: String!) {
          findUnit(unitId: $unitId) {
            id
            code
            projectId
            unitTemplateId
            isRemoved
            members
            owners
            unitTemplate {
              id
              title {
                en
                ar
              }
              project {
                id
                name {
                  en
                  ar
                }
              }
            }
          }
        }
      `,
      variables: {
        unitId
      }
    })
    return response.data.findUnit
  }
}
