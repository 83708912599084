/* eslint-disable */
import { NotificationService } from '@/store/notification/notification-service';
import Message from '@/shared/message/toastify';
import Errors from '@/shared/error/errors';
import { i18n } from '@/locales/i18n';
import router from '@/router'
import { FileUploader } from '@/shared/uploader/file-uploader'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },
    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },
    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },
    CREATE_SUCCESS(state) {
      state.saveLoading = false;
    },
    CREATE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await NotificationService.find(id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        router.push('/notification');
      }
    },

    async doSendToAll({ commit }, { notification, image }) {
      try {
        commit('CREATE_STARTED')
        // [1] Upload image to storage of firebase
        const path = `notifications`
        const { publicUrl } = image 
          ? await FileUploader.upload(path, image)
          : { publicUrl: null }

        notification['image'] = publicUrl
        await NotificationService.sendToAll(notification)
        commit('CREATE_SUCCESS')
        Message.success(i18n('notification.create.success'))
        router.push('/notification');
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
        router.push('/notification');
      }
    },

    async doSendToMultiple({ commit }, { notification, image, usersIds }) {
      try {
        commit('CREATE_STARTED')
        // [1] Upload image to storage of firebase
        const path = `notifications`
        const { publicUrl } = image 
          ? await FileUploader.upload(path, image)
          : { publicUrl: null }

        notification['image'] = publicUrl || notification['image']
        await NotificationService.sendToMultiple(notification, usersIds)
        commit('CREATE_SUCCESS')
        Message.success(i18n('notification.create.success'))
        router.push('/notification');
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
        router.push('/notification');
      }
    },

    async doSendToClient({ commit }, { notification, image, clientId }) {
      try {
        commit('CREATE_STARTED')
        const path = `notifications`
        const { publicUrl } = image 
          ? await FileUploader.upload(path, image)
          : { publicUrl: null }
        notification['image'] = publicUrl || notification['image'] || null
        notification['data'] = notification['data'] || null
        await NotificationService.sendToClient(notification, clientId)
        commit('CREATE_SUCCESS')
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    }
  },
};
