/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(
  config.backendUrlNotification
)

export class NotificationService {
  //#region [ Mutation APIs ]
  /**
   * Send notification to all users
   * - sendNotificationToAllUsers(notification: NotificationInput!): JSON!
   * @param {Object} notification
   * @param {String} notification.image
   * @param {Object} notification.title
   * @param {String} notification.title.en
   * @param {String} notification.title.ar
   * @param {Object} notification.body
   * @param {String} notification.body.en
   * @param {String} notification.body.ar
   * @returns
   */
  static async sendToAll(notification) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation NOTIFICATION_SEND_TO_ALL($notification: NotificationInput!) {
          sendNotificationToAllClients(notification: $notification)
        }
      `,

      variables: {
        notification
      }
    })

    // return response.data.sendNotificationToAllClients
    if (
      response.data.sendNotificationToAllClients &&
      response.data.sendNotificationToAllClients.status
    ) {
      return response.data.sendNotificationToAllClients.result
    } else {
      console.error(response.data.sendNotificationToAllClients.error)
      throw response.data.sendNotificationToAllClients.error
    }
  }

  /**
   * Send notification to multiple users
   * - sendNotificationToUsers(notification: NotificationInput!, usersIds: [String]!): JSON!
   * @param {Object} notification
   * @param {String} notification.image
   * @param {Object} notification.title
   * @param {String} notification.title.en
   * @param {String} notification.title.ar
   * @param {Object} notification.body
   * @param {String} notification.body.en
   * @param {String} notification.body.ar
   * @param {Object} notification.data
   * @param {String} notification.data.key
   * @param {String} notification.data.value
   * @param {String[]} usersIds
   * @returns
   */
  static async sendToMultiple(notification, usersIds) {
    debugger
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation NOTIFICATION_SEND_TO_ALL(
          $notification: NotificationInput!
          $usersIds: [String]!
        ) {
          sendNotificationToUsers(
            notification: $notification
            usersIds: $usersIds
          )
        }
      `,

      variables: {
        notification,
        usersIds
      }
    })

    debugger
    // return response.data.sendNotificationToUsers
    if (
      response.data.sendNotificationToUsers &&
      response.data.sendNotificationToUsers.status
    ) {
      return response.data.sendNotificationToUsers.result
    } else {
      console.error(response.data.sendNotificationToUsers.error)
      throw response.data.sendNotificationToUsers.error
    }
  }
  //#endregion

  static async sendToClient(notification, clientId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation NOTIFICATION_SEND_TO_ALL(
          $clientId: String!
          $notification: NotificationInput!
        ) {
          sendNotificationToClient(
            clientId: $clientId
            notification: $notification
          )
        }
      `,

      variables: {
        notification,
        clientId
      }
    })
    // return response.data.sendNotificationToClient
    if (
      response.data.sendNotificationToClient &&
      response.data.sendNotificationToClient.status
    ) {
      return response.data.sendNotificationToClient.result
    } else {
      console.error(response.data.sendNotificationToClient.error)
      throw response.data.sendNotificationToClient.error
    }
  }
  //#region [ Query APIs ]
  /**
   * Find Notification By ID
   * - findNotificationById(notificationId: String!): GeneralNotification!
   * @param {String} id
   * @returns {Promise<GeneralNotification>}
   */
  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query NOTIFICATION_FIND($notificationId: String!) {
          findNotificationById(notificationId: $notificationId) {
            id
            body
            clientId
            createdAt
            createdBy
            image
            title
            updatedBy
            updatedAt
          }
        }
      `,

      variables: {
        notificationId: id
      }
    })

    return response.data.findNotificationById
  }

  /**
   * List notifications with pagination
   * - listNotifications(pagination: PaginationInput!): [GeneralNotification]!
   * @param {Object[]} filter
   * @param {String} filter.key
   * @param {String} filter.value
   * @param {'less'|'lessEqual'|'greater'|'greaterEqual'|'equal'|'notEqual'|'startsWith'} filter.operator
   * @param {Object} pagination
   * @param {Boolean} pagination.firstPage
   * @param {Int} pagination.limit
   * @param {JSON} pagination.doc
   * @param {'next'|'prev'} pagination.action
   * @param {'asc'|'desc'} pagination.sortType
   * @param {String} pagination.orderBy
   * @returns {Promise<GeneralNotification[]>}
   */
  static async list(pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query NOTIFICATION_LIST(
          $filter: [Filter]!
          $pagination: PaginationInput!
        ) {
          listNotifications(filter: $filter, pagination: $pagination) {
            id
            body
            clientId
            createdAt
            createdBy
            image
            title
            updatedBy
            updatedAt
          }
        }
      `,

      variables: {
        filter: [],
        pagination
      }
    })

    return response.data.listNotifications
  }

  /**
   * List user notifications with pagination
   * - listUserNotifications(userId: String!, pagination: PaginationInput): [Notification]!
   * @param {String} userId
   * @param {Object} pagination
   * @param {Boolean} pagination.firstPage
   * @param {Int} pagination.limit
   * @param {JSON} pagination.doc
   * @param {'next'|'prev'} pagination.action
   * @param {'asc'|'desc'} pagination.sortType
   * @param {String} pagination.orderBy
   * @returns {Promise<Notification[]>}
   */
  static async listUserNotifications(userId, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query LIST_USER_NOTIFICATIONS(
          $userId: String!
          $pagination: PaginationInput!
        ) {
          listUserNotifications(userId: $userId, pagination: $pagination) {
            id
            image
            title
            body
            data
            action
            isRead
            isRemoved
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        userId,
        pagination
      }
    })

    return response.data.listUserNotifications
  }
  //#endregion
}
