/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlService)

export class ServicesService {
  // static async create(serviceTypeInput) {
  //   const response = await graphqlClient.mutate({
  //     mutation: gql`
  //       mutation CREATE_SERVICES_TYPE($serviceTypeInput: NameInput!) {
  //         createServiceType(serviceTypeInput: $serviceTypeInput)
  //       }
  //     `,

  //     variables: {
  //       serviceTypeInput
  //     }
  //   })

  //   if (
  //     response.data.createServiceType &&
  //     response.data.createServiceType.status
  //   ) {
  //     return response.data.createServiceType.result
  //   } else {
  //     console.error(response.data.createServiceType.error)
  //     throw response.data.createServiceType.error
  //   }
  // }
  // static async find(serviceTypeId) {
  //   const response = await graphqlClient.query({
  //     query: gql`
  //       query SERVICES_TYPE_FIND($serviceTypeId: String!) {
  //         findServiceType(serviceTypeId: $serviceTypeId) {
  //           id
  //           name {
  //             ar
  //             en
  //           }
  //           createdBy
  //           createdAt
  //           updatedAt
  //           updatedBy
  //         }
  //       }
  //     `,

  //     variables: {
  //       serviceTypeId
  //     }
  //   })

  //   return response.data.findServiceType
  // }

  static async list(pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query SERVICES_LIST($filter: [Filter]!, $pagination: PaginationInput!) {
          listServices(filter: $filter, pagination: $pagination) {
            id
            unit {
              code
            }
            isRemoved
            serviceType {
              name {
                en
                ar
              }
            }
            status
            description
            price
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        filter: [],
        pagination
      }
    })

    return response.data.listServices
  }

  static async decide(serviceDecision) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation DECIDE_MAINTENANCE($serviceDecision: ServiceDecision!) {
          decideService(serviceDecision: $serviceDecision)
        }
      `,
      variables: {
        serviceDecision
      }
    })
    if (response.data.decideService && response.data.decideService.status) {
      return response.data.decideService.result
    } else {
      console.error(response.data.decideService.error)
      throw response.data.decideService.error
    }
  }

  // static async update(id, name) {
  //   const response = await graphqlClient.mutate({
  //     mutation: gql`
  //       mutation BRAND_UPDATE($brandId: String!, $updateObject: JSON!) {
  //         editBrand(brandId: $brandId, updateObject: $updateObject)
  //       }
  //     `,

  //     variables: {
  //       brandId: id,
  //       updateObject: { name }
  //     }
  //   })

  //   // return response.data.editBrand
  //   if (response.data.editBrand && response.data.editBrand.status) {
  //     return response.data.editBrand.result
  //   } else {
  //     console.error(response.data.editBrand.error);
  //     throw response.data.editBrand.error
  //   }
  // }

  // static async remove(brandId) {
  //   const response = await graphqlClient.mutate({
  //     mutation: gql`
  //       mutation BRAND_DESTROY($brandId: String!) {
  //         removeBrand(brandId: $brandId)
  //       }
  //     `,

  //     variables: {
  //       brandId
  //     }
  //   })
  //   // return response.data.removeBrand
  //   if (response.data.removeBrand && response.data.removeBrand.status) {
  //     return response.data.removeBrand.result
  //   } else {
  //     console.error(response.data.removeBrand.error);
  //     throw response.data.removeBrand.error
  //   }
  // }
}
