/* eslint-disable */ import { SettingService } from '@/store/setting/setting-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null
  },

  getters: {
    record: state => state.record,
    findLoading: state => !!state.findLoading,
    saveLoading: state => !!state.saveLoading
  },

  mutations: {
    RESET(state) {
      state.findLoading = false
      state.saveLoading = false
      state.record = null
    },

    FIND_STARTED(state) {
      state.record = null
      state.findLoading = true
    },
    FIND_SUCCESS(state, payload) {
      state.record = payload
      state.findLoading = false
    },
    FIND_ERROR(state) {
      state.record = null
      state.findLoading = false
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true
    },
    UPDATE_SUCCESS(state) {
      state.saveLoading = false
    },
    UPDATE_ERROR(state) {
      state.saveLoading = false
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET')
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED')
        // console.log(id === 'termsAndConditions')
        let record
        switch (id) {
          case 'guidelines':
            record = await SettingService.findGuidelines()
            break
          case 'privacyPolicy':
            record = await SettingService.findPrivacyPolicy()
            break
          case 'termsAndConditions':
            record = await SettingService.findTermsAndConditions()
            break
        }
        commit('FIND_SUCCESS', record)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ERROR')
        router.push('/')
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED')
        switch (id) {
          case 'guidelines':
            await SettingService.updateGuidelines(values)
            break
          case 'privacyPolicy':
            await SettingService.updatePrivacyPolicy(values)
            break
          case 'termsAndConditions':
            await SettingService.updateTermsAndConditions(values)
            break
        }
        commit('UPDATE_SUCCESS')
        Message.success(
          i18n('settings.policies.update.success', i18n(`settings.tabs.${id}`))
        )
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
        router.push('/')
      }
    }
  }
}
