/* eslint-disable */
import settingGeneralStore from '@/store/setting/setting-general-store';
import settingPoliciesStore from '@/store/setting/setting-policies-store'

export default {
  namespaced: true,

  modules: {
    general: settingGeneralStore,
    policies: settingPoliciesStore
  }
}
