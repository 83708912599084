/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(
  config.backendUrlMaintenanceTypes
)

export class MaintenanceTypeService {
  static async create(maintenanceTypeInput) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CREATE_MAINTENANCE_TYPE(
          $maintenanceTypeInput: MaintenanceTypeInput!
        ) {
          createMaintenanceType(maintenanceTypeInput: $maintenanceTypeInput)
        }
      `,

      variables: {
        maintenanceTypeInput
      }
    })

    if (
      response.data.createMaintenanceType &&
      response.data.createMaintenanceType.status
    ) {
      return response.data.createMaintenanceType.result
    } else {
      console.error(response.data.createMaintenanceType.error)
      throw response.data.createMaintenanceType.error
    }
  }
  static async find(maintenanceTypeId) {
    const response = await graphqlClient.query({
      query: gql`
        query MAINTENANCE_TYPE_FIND($maintenanceTypeId: String!) {
          findMaintenanceType(maintenanceTypeId: $maintenanceTypeId) {
            id
            name {
              en
              ar
            }
            logo
            isRemoved
            isEnabled
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        maintenanceTypeId
      }
    })

    return response.data.findMaintenanceType
  }

  static async list(pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query MAINTENANCE_TYPE_LIST(
          $filter: [Filter]!
          $pagination: PaginationInput!
        ) {
          listMaintenanceTypes(filter: $filter, pagination: $pagination) {
            id
            name {
              en
              ar
            }
            logo
            isRemoved
            createdAt
            isEnabled
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        filter: [],
        pagination
      }
    })

    return response.data.listMaintenanceTypes
  }
  static async update(maintenanceTypeId, maintenanceTypeInput) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation MAINTENANCE_UPDATE(
          $maintenanceTypeId: String!
          $maintenanceTypeInput: MaintenanceTypeInput!
        ) {
          editMaintenanceType(
            maintenanceTypeId: $maintenanceTypeId
            maintenanceTypeInput: $maintenanceTypeInput
          )
        }
      `,
      variables: {
        maintenanceTypeId,
        maintenanceTypeInput
      }
    })
    if (
      response.data.editMaintenanceType &&
      response.data.editMaintenanceType.status
    ) {
      return response.data.editMaintenanceType.result
    } else {
      console.error(response.data.editMaintenanceType.error)
      throw response.data.editMaintenanceType.error
    }
  }

  static async setStatus(maintenanceTypeId, status) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation MAINTENANCE_TYPE_STATUS(
          $maintenanceTypeId: String!
          $status: Boolean!
        ) {
          setMaintenanceTypeStatus(
            maintenanceTypeId: $maintenanceTypeId
            status: $status
          )
        }
      `,
      variables: {
        maintenanceTypeId,
        status
      }
    })
    if (
      response.data.setMaintenanceTypeStatus &&
      response.data.setMaintenanceTypeStatus.status
    ) {
      return response.data.setMaintenanceTypeStatus.result
    } else {
      console.error(response.data.setMaintenanceTypeStatus.error)
      throw response.data.setMaintenanceTypeStatus.error
    }
  }

  static async remove(maintenanceTypeId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation MAINTENANCE_TYPE_DESTROY($maintenanceTypeId: String!) {
          removeMaintenanceType(maintenanceTypeId: $maintenanceTypeId)
        }
      `,

      variables: {
        maintenanceTypeId
      }
    })
    if (
      response.data.removeMaintenanceType &&
      response.data.removeMaintenanceType.status
    ) {
      return response.data.removeMaintenanceType.result
    } else {
      console.error(response.data.removeMaintenanceType.error)
      throw response.data.removeMaintenanceType.error
    }
  }
}
