/* eslint-disable */
import { MaintenanceTypeService } from '@/store/maintenance-type/maintenance-type-service'
import Errors from '@/shared/error/errors'
import Message from '@/shared/message/toastify'
import { i18n } from '@/locales/i18n'

const INITIAL_PAGE_SIZE = 10
const INITIAL_PAGINATION_INPUT = {
  limit: INITIAL_PAGE_SIZE,
  orderBy: 'createdAt',
  sortType: 'desc',
  action: 'next',
  firstPage: true,
  doc: {}
}

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    filter: [],
    currentPage: 1,
    pagination: INITIAL_PAGINATION_INPUT
  },

  getters: {
    loading: state => state.loading,
    exportLoading: state => state.exportLoading,
    rows: state => state.rows || [],
    count: state => state.count,
    hasRows: (state, getters) => getters.count > 0,
    filter: state => state.filter,
    currentPage: state => state.currentPage,
    orderBy: state => {
      const sorter = state.sorter

      if (!sorter) {
        return null
      }

      if (!sorter.prop) {
        return null
      }

      let direction = sorter.order === 'descending' ? 'DESC' : 'ASC'

      return `${sorter.prop}_${direction}`
    },

    limit: state => {
      const pagination = state.pagination

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE
      }

      return pagination.pageSize
    },

    offset: state => {
      const pagination = state.pagination

      if (!pagination || !pagination.pageSize) {
        return 0
      }

      const currentPage = pagination.currentPage || 1

      return (currentPage - 1) * pagination.pageSize
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        currentPage: state.currentPage,
        total: getters.count,
        showSizeChanger: true
      }
    },

    selectedRows: state => {
      return state.table ? state.table.selection : []
    }
  },

  mutations: {
    RESETED(state) {
      state.rows = []
      state.count = 0
      state.loading = false
      state.filter = []
      state.pagination = INITIAL_PAGINATION_INPUT
      state.currentPage = 1
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection()
      }
    },
    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || INITIAL_PAGINATION_INPUT
    },
    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      state.currentPage =
        payload && payload > 0 ? payload : state.currentPage || 1
    },
    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || INITIAL_PAGINATION_INPUT

      state.currentPage = state.currentPage || 1
      state.pagination = {
        ...previousPagination,
        limit: payload || INITIAL_PAGE_SIZE
      }
    },
    FETCH_STARTED(state, payload) {
      state.loading = true
      if (state.table) {
        state.table.clearSelection()
      }
      state.filter = payload && payload.filter ? payload.filter : state.filter
      state.pagination =
        payload && payload.pagination
          ? payload.pagination
          : payload.keepPagination
          ? state.pagination
          : {}
    },
    FETCH_SUCCESS(state, payload) {
      state.loading = false
      state.rows = payload.rows
      state.count = payload.count
    },
    FETCH_ERROR(state) {
      state.loading = false
      state.rows = []
      state.count = 0
    },
    REMOVE_ALL_SELECTED_STARTED(state) {
      state.loading = true
    },

    REMOVE_ALL_SELECTED_ERROR(state) {
      state.loading = false
    },

    REMOVE_ALL_SELECTED_SUCCESS(state) {
      if (state.table) {
        state.table.clearSelection()
      }
    },
    CHANGE_STATUS_SELECTED_STARTED(state) {
      state.loading = true
    },

    CHANGE_STATUS_SELECTED_ERROR(state) {
      state.loading = false
    },

    CHANGE_STATUS_SELECTED_SUCCESS(state) {
      if (state.table) {
        state.table.clearSelection()
      }
    },

    AUTOCOMPLETE_STARTED(state) {
      state.autocompleteLoading = true
      state.autocompleteList = []
    },
    AUTOCOMPLETE_SUCCESS(state, payload) {
      state.autocompleteList = payload
      state.autocompleteLoading = false
    },
    AUTOCOMPLETE_ERROR(state) {
      state.autocompleteList = []
      state.autocompleteLoading = false
    }
  },

  actions: {
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL')
    },
    async doReset({ commit, dispatch }) {
      commit('RESETED')
      return dispatch('doFetch')
    },
    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize)
      const pagination = getters.pagination
      dispatch('doFetch', { pagination, keepPagination: true })
    },

    doChangePaginationCurrentPage({ commit }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage)
    },
    async doFetchNextPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      if (rows.length) {
        const currentPage = getters.currentPage || 1
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const lastDocument = rows[rows.length - 1]
        const pagination = {
          ...previousPagination,
          action: 'next',
          firstPage: false,
          doc: lastDocument
        }
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage + 1)
        await dispatch('doFetch', { pagination })
      }
    },
    async doFetchPreviousPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      const currentPage = getters.currentPage || 1
      if (currentPage > 1 && rows.length) {
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const firstDocument = rows[0]
        const pagination = {
          ...previousPagination,
          action: 'prev',
          firstPage: false,
          doc: firstDocument
        }
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage - 1)
        await dispatch('doFetch', { pagination })
      }
    },
    async doFetch(
      { commit, getters },
      { pagination, keepPagination = true } = {}
    ) {
      try {
        commit('FETCH_STARTED', { pagination, keepPagination })

        const PaginationInput = {
          limit: pagination.limit || getters.pagination.limit,
          doc: pagination.doc || getters.pagination.doc,
          action: pagination.action || getters.pagination.action,
          orderBy: pagination.orderBy || getters.pagination.orderBy,
          sortType: pagination.sortType || getters.pagination.sortType,
          firstPage: pagination.firstPage
        }
        const response = await MaintenanceTypeService.list(PaginationInput)
        commit('FETCH_SUCCESS', {
          rows: response,
          count: response.length
        })
      } catch (error) {
        Errors.handle(error)
        commit('FETCH_ERROR')
      }
    },
    async doToggleStatus({ getters, commit, dispatch }, { id, enabled }) {
      try {
        commit('CHANGE_STATUS_SELECTED_STARTED')
        await MaintenanceTypeService.setStatus(id, enabled)
        commit('CHANGE_STATUS_SELECTED_SUCCESS')
        Message.success(
          i18n(
            `${
              enabled
                ? 'maintenanceType.enabledSuccess'
                : 'maintenanceType.disableSuccess'
            }`
          )
        )
        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination
        })
      } catch (error) {
        Errors.handle(error)
        commit('CHANGE_STATUS_SELECTED_ERROR')
        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination
        })
      }
    }
  }
}
