/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlSettings)

export class SettingService {
  static async updateGeneralSettings(settings) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SETTINGS_GENERAL_UPDATE($settings: SettingsInput!) {
          updateSettings(settings: $settings)
        }
      `,

      variables: {
        settings
      }
    })

    if (response.data.updateSettings && response.data.updateSettings.status) {
      return response.data.updateSettings.result
    } else {
      console.error(response.data.updateSettings.error)
      throw response.data.updateSettings.error
    }
  }

  static async findGeneralSettings() {
    const response = await graphqlClient.query({
      query: gql`
        query SETTINGS_GENERAL_FIND {
          getSettings {
            pointsRatio
            androidVersion
            IOSVersion
          }
        }
      `
    })

    return response.data.getSettings
  }

  static async updateTermsAndConditions(termsAndConditions) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation TERMS_AND_CONDITIONS_UPDATE($termsAndConditions: NameInput!) {
          updateTermsAndConditions(termsAndConditions: $termsAndConditions)
        }
      `,

      variables: {
        termsAndConditions
      }
    })

    // return response.data.updateTermsAndConditions
    if (
      response.data.updateTermsAndConditions &&
      response.data.updateTermsAndConditions.status
    ) {
      return response.data.updateTermsAndConditions.result
    } else {
      console.error(response.data.updateTermsAndConditions.error)
      throw response.data.updateTermsAndConditions.error
    }
  }

  static async findTermsAndConditions() {
    // console.log('findTermsAndConditions')
    const response = await graphqlClient.query({
      query: gql`
        query TERMS_AND_CONDITIONS_FIND {
          findTermsAndConditions {
            id
            value {
              en
              ar
            }
          }
        }
      `
    })
    // console.log('findTermsAndConditions')

    return response.data.findTermsAndConditions
  }

  static async updatePrivacyPolicy(privacyPolicy) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PRIVACY_POLICY_UPDATE($privacyPolicy: NameInput!) {
          updatePrivacyPolicy(privacyPolicy: $privacyPolicy)
        }
      `,

      variables: {
        privacyPolicy
      }
    })

    if (
      response.data.updatePrivacyPolicy &&
      response.data.updatePrivacyPolicy.status
    ) {
      return response.data.updatePrivacyPolicy.result
    } else {
      console.error(response.data.updatePrivacyPolicy.error)
      throw response.data.updatePrivacyPolicy.error
    }
  }

  static async findPrivacyPolicy() {
    const response = await graphqlClient.query({
      query: gql`
        query PRIVACY_POLICY_FIND {
          findPrivacyPolicy {
            id
            value {
              en
              ar
            }
          }
        }
      `
    })

    return response.data.findPrivacyPolicy
  }

  static async updateGuidelines(guideLines) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation GUIDELINES_UPDATE($guideLines: NameInput!) {
          updateGuideLines(guideLines: $guideLines)
        }
      `,

      variables: {
        guideLines
      }
    })

    if (
      response.data.updateGuideLines &&
      response.data.updateGuideLines.status
    ) {
      return response.data.updateGuideLines.result
    } else {
      console.error(response.data.updateGuideLines.error)
      throw response.data.updateGuideLines.error
    }
  }

  static async findGuidelines() {
    const response = await graphqlClient.query({
      query: gql`
        query GUIDELINES_FIND {
          findGuideLines {
            id
            value {
              en
              ar
            }
          }
        }
      `
    })

    return response.data.findGuideLines
  }
}
