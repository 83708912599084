/* eslint-disable */
import { ServicesTypeService } from '@/store/services-type/services-type-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'

export default {
  namespaced: true,

  state: {
    loading: false
  },

  getters: {
    loading: state => !!state.loading
  },

  mutations: {
    DESTROY_ALL_STARTED(state) {
      state.loading = true
    },

    DESTROY_ALL_SUCCESS(state) {
      state.loading = false
    },

    DESTROY_ALL_ERROR(state) {
      state.loading = false
    },

    DESTROY_STARTED(state) {
      state.loading = true
    },

    DESTROY_SUCCESS(state) {
      state.loading = false
    },

    DESTROY_ERROR(state) {
      state.loading = false
    }
  },

  actions: {
    async doDestroy({ commit, dispatch, rootGetters }, id) {
      try {
        commit('DESTROY_STARTED')

        await ServicesTypeService.remove(id)

        commit('DESTROY_SUCCESS')
        Message.success(i18n('servicesType.destroy.success'))

        dispatch(
          `servicesType/list/doFetch`,
          {
            pagination: rootGetters[`servicesType/list/pagination`]
          },
          { root: true }
        )
      } catch (error) {
        Errors.handle(error)
        commit('DESTROY_ERROR')
      }
    }
  }
}
