<template>
  <div class="intro-y box p-2 sm:p-5 mt-5">
    <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
      <form
        id="tabulator-html-filter-form"
        class="flex flex-col tab:flex-row tab:items-center gap-2 w-full"
        :class="isRTL ? 'sm:ml-auto' : 'sm:mr-auto'"
        v-if="filterFields.length"
      >
        <div class="flex tab:flex-row flex-col tab:items-center items-stretch">
          <label class="w-24 flex-none md:w-auto md:flex-initial mr-2 ml-2">{{
            i18n('common.table.filter.field')
          }}</label>
          <select
            v-model="selectedField"
            @change="resetAll()"
            class="form-select w-full 2xl:w-full mt-2 sm:mt-0 dark:bg-dark-9 flex-1"
          >
            <option
              v-for="(field, index) in filterFields"
              :key="index"
              :value="field"
            >
              {{ field.label }}
            </option>
          </select>
        </div>

        <div class="sm:flex items-center xl:mt-0">
          <input
            v-if="selectedField.type == 'text'"
            v-model="filterValue"
            @keydown.enter.exact.prevent="doFilter"
            type="text"
            class="form-control 2xl:w-full mt-2 sm:mt-0 dark:bg-dark-9"
          />

          <input
            v-if="selectedField.type == 'number'"
            v-model="filterValue"
            @keydown.enter.exact.prevent="doFilter"
            type="number"
            class="form-control 2xl:w-full mt-2 sm:mt-0 dark:bg-dark-9"
          />

          <div v-if="selectedField.type == 'number-range'">
            <div class="flex items-center gap-2">
              <label for="">{{ i18n('common.from') }}</label>
              <input
                v-model="filterValueFrom"
                @keydown.enter.exact.prevent="doFilter"
                type="number"
                class="form-control 2xl:w-full mt-2 sm:mt-0 dark:bg-dark-9"
              />
              <label for="">{{ i18n('common.to') }}</label>
              <input
                v-model="filterValueTo"
                @keydown.enter.exact.prevent="doFilter"
                type="number"
                :min="filterValueFrom"
                class="form-control 2xl:w-full mt-2 sm:mt-0 dark:bg-dark-9"
              />
            </div>
          </div>

          <TomSelect
            v-if="selectedField.type == 'boolean'"
            v-model="filterValue"
            class="w-full sm:w-32 mt-2 sm:mt-0 dark:bg-dark-9"
          >
            <option :value="true" class="">{{ true }}</option>
            <option :value="false">{{ false }}</option>
          </TomSelect>

          <div v-if="selectedField.type === 'list'">
            <TomSelect
              v-model="filterValue"
              class="w-full sm:w-32 mt-2 sm:mt-0 dark:bg-dark-9"
            >
              <option
                v-for="(option, index) in selectedField.options"
                :key="index"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </TomSelect>
          </div>
          <div v-if="selectedField.type === 'listAsync'">
            <v-select
              :options="selectedField.options || []"
              :label="labelLocalized"
              v-model="filterValue"
              class="w-full sm:w-56 mt-2 sm:mt-0"
              style="height: 38px"
              :loading="selectedField.loading"
            >
              <template #no-options>
                <div>
                  <!-- {{ isRTL ? 'لا يوجد مشاريع' : 'No projects' }} -->
                  {{ selectedField.noOptionsLabel }}
                </div>
              </template>
              <template #spinner="{ loading }">
                <div v-if="loading" class="vs__spinner"></div> </template
            ></v-select>
          </div>

          <!-- <select v-if="selectedField.type == 'boolean'"
          v-model="filterValue"
          class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 dark:bg-dark-9"
        >
          <option :value="true" selected>{{ true }}</option>
          <option :value="false">{{ false }}</option>
        </select> -->

          <div v-if="selectedField.type == 'date'">
            <div class="preview">
              <div class="relative w-56 mx-auto">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
                >
                  <CalendarIcon class="w-4 h-4" />
                </div>
                <Litepicker
                  v-model="filterValue"
                  :options="{
                    autoApply: false,
                    showWeekNumbers: true,
                    dropdowns: {
                      minYear: 1990,
                      maxYear: null,
                      months: true,
                      years: true
                    }
                  }"
                  class="form-control"
                  :class="isRTL ? 'pr-12' : 'pl-12'"
                />
              </div>
            </div>
          </div>

          <div v-if="selectedField.type == 'date-range'">
            <div class="w-full sm:w-48 2xl:w-full mt-2 sm:mt-0">
              <Litepicker
                v-model="filterValue"
                v-if="isRTL"
                :options="{
                  lang: 'ar',
                  buttonText: {
                    apply: 'طبق',
                    cancel: 'إلغاء'
                  },
                  tooltipText: {
                    one: 'يوم',
                    two: 'يومان',
                    few: 'أيام',
                    many: 'أيام'
                  },
                  autoApply: false,
                  singleMode: false,
                  numberOfColumns: 2,
                  numberOfMonths: 2,
                  showWeekNumbers: true,
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true
                  }
                }"
                class="form-control dark:bg-dark-9"
              />
              <Litepicker
                v-model="filterValue"
                v-else
                :options="{
                  autoApply: false,
                  singleMode: false,
                  numberOfColumns: 2,
                  numberOfMonths: 2,
                  showWeekNumbers: true,
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true
                  }
                }"
                class="form-control dark:bg-dark-9"
              />
            </div>
          </div>
        </div>

        <div id="form-actions" class="xl:mt-0 flex items-center gap-2">
          <button
            type="button"
            class="btn bg-theme-31 text-white w-full"
            :disabled="disabled"
            @click="doFilter()"
          >
            {{ i18n('common.table.filter.go') }}
          </button>
          <button
            type="button"
            class="btn bg-theme-32 text-white w-full"
            :disabled="disabled"
            @click="$emit('reset')"
          >
            {{ i18n('common.table.filter.reset') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
// const inputTypes = ['text', 'number', 'date', 'date-range', 'email', '']
import HelperClass from '@/shared/helperClass'

import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default defineComponent({
  props: {
    filterFields: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedField: this.filterFields.length ? this.filterFields[0] : '',
      filterValue: '',
      filterValueFrom: 0,
      filterValueTo: 0
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    labelLocalized() {
      return this.isRTL ? 'nameAr' : 'nameEn'
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    resetAll() {
      this.filterValue = ''
    },
    localizeKey(key) {
      return HelperClass.isArabic(this.filterValue) ? `${key}.ar` : `${key}.en`
    },
    doFilter() {
      let filter
      let orderBy = null
      if (this.selectedField.type === 'date') {
        const date = dayjs(this.filterValue).utc(true).format()
        filter = {
          key: this.selectedField.value,
          operator: 'equal',
          value: date
        }
      } else if (this.selectedField.type === 'date-range') {
        const dates = this.filterValue.split(' - ')
        const firstDay = dayjs(dates[0]).utc(true).format()
        const lastDay = dayjs(dates[1]).utc(true).format()
        filter = [
          {
            key: this.selectedField.value,
            operator: 'greaterEqual',
            value: firstDay
          },
          {
            key: this.selectedField.value,
            operator: 'lessEqual',
            value: lastDay
          }
        ]
        orderBy = this.selectedField.value
      } else if (this.selectedField.type === 'number-range') {
        filter = [
          {
            key: this.selectedField.value,
            operator: 'greaterEqual',
            value: this.filterValueFrom.toString()
          },
          {
            key: this.selectedField.value,
            operator: 'lessEqual',
            value: this.filterValueTo.toString()
          }
        ]
        orderBy = this.selectedField.value
      } else if (this.selectedField.type === 'text') {
        const key = this.selectedField.localize
          ? this.localizeKey(this.selectedField.value)
          : this.selectedField.value
        filter = {
          key,
          operator: 'startsWith',
          value: this.filterValue
        }
        orderBy = key
      } else if (this.selectedField.type === 'bool') {
        filter = {
          key: this.selectedField.value,
          operator: 'startsWith',
          // eslint-disable-next-line
          value: this.filterValue === 'true' ? true : false
        }
      } else if (this.selectedField.type === 'listAsync') {
        filter = {
          key: this.selectedField.value,
          operator: 'equal',
          value: this.filterValue.id
        }
      } else {
        filter = {
          key: this.selectedField.value,
          operator: 'equal',
          value: this.filterValue
        }
      }
      this.$emit('filter', filter, orderBy)
    }
  }
})
</script>

<style lang="scss">
.tom__select {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  border-radius: 0.375rem !important;
  border-width: 1px !important;
}

.vs__dropdown-toggle {
  padding: 4px 0 !important;
  border-color: rgba(226, 232, 240) !important;
}
</style>
