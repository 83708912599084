import projectListStore from '@/store/project/project-list-store'
import projectFormStore from '@/store/project/project-form-store'
import projectViewStore from '@/store/project/project-view-store'

export default {
  namespaced: true,
  modules: {
    list: projectListStore,
    form: projectFormStore,
    view: projectViewStore
  }
}
