/* eslint-disable */
import FeedbackListStore from '@/store/feedback/feedback-list-store'

export default {
  namespaced: true,

  modules: {
    list: FeedbackListStore
  }
}
