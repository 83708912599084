/* eslint-disable */
import ServicesTypeListStore from '@/store/services-type/services-type-list-store'
import ServicesTypeFormStore from '@/store/services-type/services-type-form-store'
import ServicesTypeDestroyStore from '@/store/services-type/services-type-destroy-store'

export default {
  namespaced: true,

  modules: {
    list: ServicesTypeListStore,
    form: ServicesTypeFormStore,
    destroy: ServicesTypeDestroyStore
  }
}
