/* eslint-disable */

import { UnitService } from '@/store/unit/unit-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null
  },

  getters: {
    record: state => state.record,
    findLoading: state => !!state.findLoading,
    saveLoading: state => !!state.saveLoading
  },

  mutations: {
    RESET(state) {
      state.findLoading = false
      state.saveLoading = false
      state.record = null
    },

    FIND_STARTED(state) {
      state.record = null
      state.findLoading = true
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload
      state.findLoading = false
    },

    FIND_ERROR(state) {
      state.record = null
      state.findLoading = false
    },

    ADD_STARTED(state) {
      state.saveLoading = true
    },

    ADD_SUCCESS(state) {
      state.saveLoading = false
    },

    ADD_ERROR(state) {
      state.saveLoading = false
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET')
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED')
        const record = await UnitService.find(id)
        commit('FIND_SUCCESS', record)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ERROR')
        router.back()
      }
    },

    async doAdd({ commit }, values) {
      try {
        commit('ADD_STARTED')
        const record = await UnitService.create(values)
        commit('ADD_SUCCESS')
        Message.success(i18n('units.create.success'))
        router.back()
      } catch (error) {
        Errors.handle(error)
        commit('ADD_ERROR')
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED')
        await UnitService.update(id, values)
        commit('UPDATE_SUCCESS')
        Message.success(i18n('units.update.success'))
        router.back()
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    }
  }
}
