// import Permissions from '@/security/permissions'

const state = () => {
  return {
    menu: [
      // {
      //   icon: 'HomeIcon',
      //   pageName: 'home',
      //   title: 'Dashboard'
      // },
      {
        icon: 'UserCheckIcon',
        pageName: 'admins',
        title: 'Admins'
        // permission: Permissions.values.adminRead
      },
      {
        icon: 'UsersIcon',
        pageName: 'users',
        title: 'Users'
        // permission: Permissions.values.userRead
      },
      {
        icon: 'UsersIcon',
        pageName: 'securities',
        title: 'securities'
        // permission: Permissions.values.userRead
      },
      {
        icon: 'ListIcon',
        pageName: 'projects',
        title: 'Projects'
        // permission: Permissions.values.categoryRead
      },
      {
        icon: 'BoxIcon',
        pageName: 'units',
        title: 'units'
        // permission: Permissions.values.categoryRead
      },
      {
        icon: 'CodesandboxIcon',
        pageName: 'servicesType',
        title: 'servicesType'
        // permission: Permissions.values.brandRead
      },
      {
        icon: 'HelpCircleIcon',
        pageName: 'service',
        title: 'service'
        // permission: Permissions.values.brandRead
      },
      {
        icon: 'CodesandboxIcon',
        pageName: 'maintenanceType',
        title: 'maintenanceType'
        // permission: Permissions.values.brandRead
      },
      {
        icon: 'ToolIcon',
        pageName: 'maintenance',
        title: 'maintenance'
        // permission: Permissions.values.brandRead
      },
      {
        icon: 'EditIcon',
        pageName: 'feedback',
        title: 'feedback'
        // permission: Permissions.values.brandRead
      },
      {
        icon: 'LockIcon',
        pageName: 'security',
        title: 'security'
        // permission: Permissions.values.brandRead
      },
      // {
      //   icon: 'DatabaseIcon',
      //   pageName: 'products',
      //   title: 'Products',
      //   permission: Permissions.values.productRead
      // },
      // {
      //   icon: 'ArchiveIcon',
      //   pageName: 'orders',
      //   title: 'Requests',
      //   permission: Permissions.values.orderRead
      // },
      // {
      //   icon: 'DollarSignIcon',
      //   pageName: 'payment',
      //   title: 'Payments',
      //   permission: [
      //     Permissions.values.requestRead,
      //     Permissions.values.walletRead
      //   ]
      // },
      {
        icon: 'BellIcon',
        pageName: 'notification',
        title: 'Notifications'
        // permission: Permissions.values.notificationRead
      },
      // {
      //   icon: 'GiftIcon',
      //   pageName: 'promoCode',
      //   title: 'PromoCode',
      //   permission: Permissions.values.promoCodeRead
      // },
      {
        icon: 'LayersIcon',
        pageName: 'slider',
        title: 'Slider'
        // permission: Permissions.values.sliderRead
      },
      {
        icon: 'CalendarIcon',
        pageName: 'event',
        title: 'Event'
        // permission: Permissions.values.sliderRead
      },
      // {
      //   // icon: 'BarChartIcon',
      //   icon: 'BarChart2Icon',
      //   // icon: 'FileTextIcon',
      //   pageName: 'reports',
      //   title: 'Reports',
      //   permission: Permissions.values.reportRead
      // },
      {
        icon: 'MessageSquareIcon',
        pageName: 'complaint',
        title: 'Complaints'
        // permission: Permissions.values.complaintRead
      },
      {
        icon: 'InfoIcon',
        pageName: 'sales-requests',
        title: 'sales-requests'
        // permission: Permissions.values.complaintRead
      },
      {
        icon: 'SettingsIcon',
        pageName: 'settings',
        title: 'Settings'
        // permission: Permissions.values.settingRead
      },
      'devider'
    ]
  }
}

const getters = {
  menu: state => state.menu
}
const mutations = {}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
