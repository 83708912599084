import { createStore } from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import main from './main'
import sideMenu from './side-menu'
import layout from '@/store/layout-store'
import auth from '@/store/auth/auth-store'
import admin from '@/store/admin/admin-store'
import slider from '@/store/slider/slider-store'
import lead from '@/store/lead/lead-store'
import setting from '@/store/setting/setting-store'
import project from '@/store/project/project-store'
import template from '@/store/template/template-store'
import unit from '@/store/unit/unit-store'
import user from '@/store/user/user-store'
import event from '@/store/event/event-store'
import servicesType from '@/store/services-type/services-type-store'
import service from '@/store/service/service-store'
import security from '@/store/security/security-store'
import maintenanceType from '@/store/maintenance-type/maintenance-type-store'
import maintenance from '@/store/maintenance/maintenance-store'
import notification from '@/store/notification/notification-store'
import complaintStore from './complaint/complaint-store'
import feedback from '@/store/feedback/feedback-store'

const store = createStore({
  modules: {
    main,
    sideMenu,
    layout,
    auth,
    admin,
    slider,
    setting,
    lead,
    project,
    template,
    user,
    unit,
    event,
    servicesType,
    service,
    security,
    maintenanceType,
    maintenance,
    notification,
    complaintStore,
    feedback
  },
  mutations: {
    ...vuexfireMutations
  }
})

export function useStore() {
  return store
}

export default store
