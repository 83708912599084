/* eslint-disable */
import MaintenanceListStore from '@/store/maintenance/maintenance-list-store'
import MaintenanceChatStore from '@/store/maintenance/maintenance-chat-store'

export default {
  namespaced: true,

  modules: {
    list: MaintenanceListStore,
    chat: MaintenanceChatStore
  }
}
