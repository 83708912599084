/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlEvent)

export class EventService {
  /**
   * Create new slider
   * - addSlider(sliderInput: SliderInput!): JSON!
   * @param {Object} data
   * @param {String} data.id
   * @param {String} data.imageEn
   * @param {String} data.imageAr
   * @param {Object} data.title
   * @param {String} data.title.en
   * @param {String} data.title.ar
   * @param {Object} data.description
   * @param {String} data.description.en
   * @param {String} data.description.ar
   * @returns {Promise}
   */
  static async create(eventInput) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation EVENT_CREATE($eventInput: EventInput!) {
          createEvent(eventInput: $eventInput)
        }
      `,

      variables: {
        eventInput
      }
    })
    if (response.data.createEvent && response.data.createEvent.status) {
      return response.data.createEvent.result
    } else {
      console.error(response.data.createEvent.error)
      throw response.data.createEvent.error
    }
  }

  /**
   * Update slider
   * - updateSlider(sliderId: String!, sliderUpdate: SliderUpdate!): JSON!
   * @param {String} id - Slider ID
   * @param {Object} data
   * @param {String} data.imageEn
   * @param {String} data.imageAr
   * @param {Object} data.title
   * @param {String} data.title.en
   * @param {String} data.title.ar
   * @param {Object} data.description
   * @param {String} data.description.en
   * @param {String} data.description.ar
   * @returns {Promise}
   */
  static async update(eventId, eventUpdate) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation EVENT_UPDATE($eventId: String!, $eventUpdate: EventUpdate!) {
          editEvent(eventId: $eventId, eventUpdate: $eventUpdate)
        }
      `,

      variables: {
        eventId,
        eventUpdate
      }
    })

    if (response.data.editEvent && response.data.editEvent.status) {
      return response.data.editEvent.result
    } else {
      console.error(response.data.editEvent.error)
      throw response.data.editEvent.error
    }
  }

  /**
   * Delete slider by ID
   * - removeSlider(sliderId: String!): JSON!
   * @param {String} sliderId
   * @returns {Promise}
   */
  static async remove(eventId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation EVENT_DESTROY($eventId: String!) {
          removeEvent(eventId: $eventId)
        }
      `,

      variables: {
        eventId
      }
    })

    if (response.data.removeEvent && response.data.removeEvent.status) {
      return response.data.removeEvent.result
    } else {
      console.error(response.data.removeEvent.error)
      throw response.data.removeEvent.error
    }
  }
  //#endregion

  //#region [ Query APIs ]
  /**
   * Find Slider By ID
   * - findSliderById(sliderId: String!): Slider!
   * @param {String} id - Slider ID
   * @returns {Promise<Slider>}
   */
  static async find(eventId) {
    const response = await graphqlClient.query({
      query: gql`
        query EVENT_FIND($eventId: String!) {
          findEvent(eventId: $eventId) {
            id
            title {
              en
              ar
            }
            description {
              en
              ar
            }
            isRemoved
            image {
              en
              ar
            }
            location {
              ar
              en
            }
            eventTime
            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,

      variables: {
        eventId
      }
    })

    return response.data.findEvent
  }

  /**
   * List all sliders
   * - listSliders: [Slider]!
   * @returns {Promise<Slider[]>}
   */
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query EVENT_LIST($filter: [Filter]!, $pagination: PaginationInput!) {
          listEvents(filter: $filter, pagination: $pagination) {
            id
            title {
              en
              ar
            }
            description {
              en
              ar
            }
            eventTime
            location {
              en
              ar
            }
            isRemoved
            image {
              en
              ar
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        filter,
        pagination
      }
    })

    return response.data.listEvents
  }
  //#endregion
}
