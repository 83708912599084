/* eslint-disable */
import { UserService } from '@/store/user/user-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    uploadLoading: false,
    record: null
  },

  getters: {
    record: state => state.record,
    findLoading: state => !!state.findLoading,
    saveLoading: state => !!state.saveLoading,
    uploadLoading: state => !!state.uploadLoading
  },

  mutations: {
    RESET(state) {
      state.findLoading = false
      state.saveLoading = false
      state.uploadLoading = false
      state.record = null
    },

    FIND_STARTED(state) {
      state.record = null
      state.findLoading = true
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload
      state.findLoading = false
    },

    FIND_ERROR(state) {
      state.record = null
      state.findLoading = false
    },

    ASSIGN_STARTED(state) {
      state.findLoading = true
    },

    ASSIGN_SUCCESS(state, payload) {
      state.findLoading = false
    },

    ASSIGN_ERROR(state) {
      state.findLoading = false
    },

    ADD_STARTED(state) {
      state.saveLoading = true
    },

    ADD_SUCCESS(state) {
      state.saveLoading = false
    },

    ADD_ERROR(state) {
      state.saveLoading = false
    },
    UPLOAD_FILE_STARTED(state) {
      state.uploadLoading = true
    },

    UPLOAD_FILE_SUCCESS(state) {
      state.uploadLoading = false
    },

    UPLOAD_FILE_ERROR(state) {
      state.uploadLoading = false
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET')
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED')
        const record = await UserService.find(id)
        commit('FIND_SUCCESS', record)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ERROR')
        router.push('/users')
      }
    },
    async doFindSecurity({ commit }, id) {
      try {
        commit('FIND_STARTED')
        const record = await UserService.findSecurity(id)
        commit('FIND_SUCCESS', record)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ERROR')
        router.push('/securities')
      }
    },
    async doAssignOwned({ commit }, { clientId, unitId }) {
      try {
        commit('ASSIGN_STARTED')
        const record = await UserService.assignOwnedUnit(clientId, unitId)
        commit('ASSIGN_SUCCESS')
        Message.success(i18n('user.assign.success'))
        router.back()
      } catch (error) {
        Errors.handle(error)
        commit('ASSIGN_ERROR')
        router.back()
      }
    },
    async doAssignMembered({ commit }, { clientId, unitId }) {
      try {
        commit('ASSIGN_STARTED')
        const record = await UserService.assignMemberedUnit(clientId, unitId)
        commit('ASSIGN_SUCCESS')
        Message.success(i18n('user.assign.success'))
        router.back()
      } catch (error) {
        Errors.handle(error)
        commit('ASSIGN_ERROR')
        router.back()
      }
    },
    async doAdd({ commit }, values) {
      try {
        commit('ADD_STARTED')
        const record = await UserService.create(values)
        commit('ADD_SUCCESS')
        Message.success(i18n('user.create.success'))
        router.push('/users')
      } catch (error) {
        Errors.handle(error)
        commit('ADD_ERROR')
      }
    },
    async doUploadFile({ commit }, link) {
      try {
        commit('UPLOAD_FILE_STARTED')
        await UserService.uploadFile(link)
        commit('UPLOAD_FILE_SUCCESS')
        Message.success(i18n('user.create.fileSuccess'))
      } catch (error) {
        Errors.handle(error)
        commit('UPLOAD_FILE_ERROR')
      }
    },
    async doAddSecurity({ commit }, values) {
      try {
        commit('ADD_STARTED')

        const record = await UserService.createSecurity(values)
        commit('ADD_SUCCESS')
        Message.success(i18n('securities.create.success'))
        router.push('/securities')
      } catch (error) {
        Errors.handle(error)
        commit('ADD_ERROR')
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED')
        await UserService.edit(id, values)
        commit('UPDATE_SUCCESS')
        Message.success(i18n('user.update.success'))
        router.push('/users')
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    },
    async doUpdateSecurity({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED')

        await UserService.editSecurity(id, values)
        commit('UPDATE_SUCCESS')
        Message.success(i18n('user.update.success'))
        router.push('/securities')
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    }

    // async doSetAdminAccess(
    //   { commit, rootGetters, dispatch },
    //   { id, pagesAccess }
    // ) {
    //   try {
    //     commit('UPDATE_STARTED')
    //     await UserService.setAdminAccess(id, pagesAccess)
    //     commit('UPDATE_SUCCESS')
    //     const currentUser = rootGetters['auth/currentUser']
    //     if (currentUser.id === id) {
    //       dispatch('auth/doRefreshCurrentUser')
    //     }
    //     Message.success(i18n('admin.update.success'))
    //     router.push('/admins')
    //   } catch (error) {
    //     Errors.handle(error)
    //     commit('UPDATE_ERROR')
    //   }
    // }
  }
}
