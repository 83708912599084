import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlTemplate)

export class TemplateService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query TEMPLATE_LIST($filter: [Filter]!, $pagination: PaginationInput!) {
          listUnitTemplates(filter: $filter, pagination: $pagination) {
            id
            title {
              ar
              en
            }
            bathrooms
            description {
              ar
              en
            }
            area
            garage
            unitType
            finish
            images
            isRemoved
            projectId
            rooms
            createdAt
            createdBy
          }
        }
      `,
      variables: {
        filter,
        pagination
      }
    })
    return response.data.listUnitTemplates
  }

  static async create(unitTemplateInput) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation TEMPLATE_CREATE($unitTemplateInput: UnitTemplateInput!) {
          createUnitTemplate(unitTemplateInput: $unitTemplateInput)
        }
      `,
      variables: {
        unitTemplateInput
      }
    })
    if (
      response.data.createUnitTemplate &&
      response.data.createUnitTemplate.status
    ) {
      return response.data.createUnitTemplate.result
    } else {
      throw response.data.createUnitTemplate.error
    }
  }

  static async update(unitTemplateId, unitTemplateUpdate) {
    //
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation TEMPLATE_UPDATE(
          $unitTemplateId: String!
          $unitTemplateUpdate: UnitTemplateUpdate!
        ) {
          editUnitTemplate(
            unitTemplateId: $unitTemplateId
            unitTemplateUpdate: $unitTemplateUpdate
          )
        }
      `,
      variables: {
        unitTemplateId,
        unitTemplateUpdate
      }
    })
    if (
      response.data.editUnitTemplate &&
      response.data.editUnitTemplate.status
    ) {
      return response.data.editUnitTemplate.result
    } else {
      throw response.data.editUnitTemplate.error
    }
  }

  static async find(unitTemplateId) {
    const response = await graphqlClient.query({
      query: gql`
        query TEMPLATE_FIND($unitTemplateId: String!) {
          findUnitTemplate(unitTemplateId: $unitTemplateId) {
            id
            title {
              ar
              en
            }
            bathrooms
            description {
              ar
              en
            }
            area
            unitType
            finish
            garage
            images
            isRemoved
            projectId
            rooms
            createdAt
            createdBy
          }
        }
      `,
      variables: {
        unitTemplateId
      }
    })

    return response.data.findUnitTemplate
  }

  static async remove(unitTemplateId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation LEAD_DESTROY($unitTemplateId: String!) {
          removeUnitTemplate(unitTemplateId: $unitTemplateId)
        }
      `,
      variables: {
        unitTemplateId
      }
    })
    if (
      response.data.removeUnitTemplate &&
      response.data.removeUnitTemplate.status
    ) {
      return response.data.removeUnitTemplate.result
    } else {
      console.error(response.data.removeUnitTemplate.error)
      throw response.data.removeUnitTemplate.error
    }
  }
}
