/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlSecurity)

export class SecurityService {
  static async list(pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query SERVICES_LIST($filter: [Filter]!, $pagination: PaginationInput!) {
          listSecurities(filter: $filter, pagination: $pagination) {
            id
            isRemoved
            securityString
            unit {
              code
            }
            createdAt
            createdBy
            visitorName
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        filter: [],
        pagination
      }
    })

    return response.data.listSecurities
  }
}
