import templateListStore from '@/store/template/template-list-store'
import templateFormStore from '@/store/template/template-form-store'
import templateViewStore from '@/store/template/template-view-store'

export default {
  namespaced: true,
  modules: {
    list: templateListStore,
    form: templateFormStore,
    view: templateViewStore
  }
}
