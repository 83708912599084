/* eslint-disable */
import {
  collection,
  doc,
  query,
  where,
  orderBy,
  onSnapshot,
  getFirestore,
  serverTimestamp,
  setDoc,
  updateDoc,
  getDoc,
  getDocs
} from 'firebase/firestore'
import FirebaseRepository from '@/shared/firebase/firebase-repository'

const state = {
  chat: {},
  messages: [],
  showChatBox: false,
  unReadChats: [],
  isTyping: false,
  typingList: [],
  complaintsFirestore: [],
  messagesFirestore: [],
  newComplaintsCount: 0
}

const getters = {
  chat: state => state.chat || {},
  messages: state => state.messages || [],
  showChatBox: state => state.showChatBox,
  unReadChats: state => state.unReadChats || [],
  isTyping: state => state.isTyping,
  typingList: state => state.typingList || [],
  complaintsFirestore: state => state.complaintsFirestore || [],
  messagesFirestore: state => state.messagesFirestore || [],
  newComplaintsCount: state => state.newComplaintsCount || 0
}

const mutations = {
  SET_NUMBER_OF_NEW_CHAT(state, payload) {
    state.newComplaintsCount = payload
  },
  SET_MESSAGE_CREATOR(state, payload) {
    let message = state.messages.filter(el => {
      el.id == payload.messageId
    })
    message.creator = payload.creator
  },
  SET_CHAT_CLIENT(state, payload) {
    let chat = state.chat
    chat.client = payload
  },
  APPEND_TO_MESSAGES(state, payload) {
    state.messages.push(payload)
  },
  APPEND_TO_CHAT(state, payload) {
    state.complaints.push(payload)
  },
  RESET_MESSAGES(state) {
    state.messages = []
    state.showChatBox = false
    state.isTyping = false
  },
  RESET_CHAT(state) {
    state.chat = {}
    state.showChatBox = false
    state.isTyping = false
  },
  FETCH_CHAT_SUCCESS(state, payload) {
    state.chat = payload
  },
  FETCH_MESSAGES_SUCCESS(state, payload) {
    state.messages = payload
    state.showChatBox = true
  },
  SET_UNREAD_CHATS(state, payload) {
    state.unReadChats = payload
  },
  UPDATE_TYPING_STATUS(state, payload) {
    state.isTyping = payload
  },
  UPDATE_TYPING_LIST(state, payload) {
    state.typingList = payload
  }
}

const actions = {
  resetChat({ commit }) {
    commit('RESET_CHAT')
  },
  resetMessages({ commit }) {
    commit('RESET_MESSAGES')
  },
  // doRunListenerOnNewChat({ commit }) {
  //   try {
  //     const DB = getFirestore()
  //     const QUERY = query(
  //       collection(DB, 'complaints'),
  //       where('status', '==', 'pending')
  //     )
  //     onSnapshot(QUERY, querySnapshot => {
  //
  //       commit('SET_NUMBER_OF_NEW_CHAT', querySnapshot.size)
  //     })
  //   } catch (error) {
  //     throw error
  //   }
  // },
  doListenOnTyping({ commit }, { serviceId }) {
    const DB = getFirestore()
    onSnapshot(doc(DB, `services/${serviceId}/chats/${serviceId}`), doc => {
      const chat = doc.data()
      commit('UPDATE_TYPING_STATUS', !!chat.userTyping)
    })
  },
  async doListenOnUnReadMessages({ commit }) {
    try {
      const DB = getFirestore()
      const unReadChats = []
      const servicesCollection = collection(DB, 'services')
      const servicesSnapshot = await getDocs(servicesCollection)
      for (const services of servicesSnapshot.docs) {
        const chatCollection = collection(services.ref, 'chats')
        const q = query(chatCollection, where('unreadMessages', '==', true))
        const [chat] = (await getDocs(q)).docs
        chat && unReadChats.push(chat.data().id)
      }
      commit('SET_UNREAD_CHATS', unReadChats)
    } catch (error) {
      console.error('ERROR====>', error)
    }
  },
  async fetchChat({ commit, dispatch }, { serviceId }) {
    try {
      commit('RESET_CHAT')
      const DB = getFirestore()
      const chatRef = await getDoc(
        doc(DB, `services/${serviceId}/chats/${serviceId}`)
      )
      if (chatRef.exists()) {
        commit('FETCH_CHAT_SUCCESS', chatRef.data())
      }
      dispatch('fetchServiceSender')
      dispatch('doSetUnreadMessages', serviceId)
      // const QUERY = query(
      //   collection(DB, `services/${id}/chat`),
      //   where('status', '==', status),
      //   orderBy('createdAt', 'desc')
      // )
      // onSnapshot(QUERY, querySnapshot => {
      //   const complaints = FirebaseRepository.mapCollection(querySnapshot)
      //   commit('FETCH_CHAT_SUCCESS', complaints)
      //   commit(
      //     'UPDATE_TYPING_LIST',
      //     complaints
      //       .filter(complaint => complaint.userTyping)
      //       .map(complaint => complaint.id)
      //   )
      //   dispatch('fetchComplaintSender')
      // })
    } catch (error) {
      throw error
    }
  },
  async fetchMessages({ commit, dispatch, rootGetters }, { serviceId }) {
    try {
      commit('RESET_MESSAGES')
      const currentUser = rootGetters[`auth/currentUser`] || { id: null }
      const DB = getFirestore()
      const QUERY = query(
        collection(DB, `services/${serviceId}/chats/${serviceId}/messages`),
        orderBy('sentAt', 'asc')
      )
      onSnapshot(QUERY, querySnapshot => {
        const messages = FirebaseRepository.mapCollection(querySnapshot)
        commit('FETCH_MESSAGES_SUCCESS', messages)
        dispatch('doListenOnTyping', { serviceId })
        const messagesIds = messages
          .filter(
            message => message.senderId != currentUser.id && !message.read
          )
          .map(message => message.id)
        dispatch('doSetIsReading', { serviceId, messagesIds })
      })
    } catch (error) {
      throw error
    }
  },
  async fetchServiceSender({ getters, commit }) {
    const chat = getters.chat
    if (!chat.client) {
      const client = await FirebaseRepository.findDocument(
        'clients',
        chat.clientId
      )
      chat.client = client || { id: chat.clientId }
      commit('SET_CHAT_CLIENT', client)
    }
  },
  fetchMessageSender({ getters, commit }) {
    getters.messages.forEach(async message => {
      if (!message.creator) {
        const creator = await FirebaseRepository.findDocument(
          'clients',
          message.senderId
        )
        message.creator = creator || { id: message.senderId }
        commit('SET_MESSAGE_CREATOR', {
          messageId: message.id,
          creator: creator
        })
      }
    })
  },

  async doSendMessage({ rootGetters, dispatch }, payload) {
    const DB = getFirestore()
    const id = doc(collection(DB, 'ids')).id
    const currentUser = rootGetters[`auth/currentUser`] || { id: null }

    const message = {
      id: id,
      message: payload.message || null,
      attachment: payload.attachment || null,
      type: payload.type || null,
      read: false,
      senderId: currentUser.id,
      sentAt: serverTimestamp()
    }
    await setDoc(
      doc(
        DB,
        `services/${payload.serviceId}/chats/${payload.serviceId}/messages/${id}`
      ),
      message
    )
    await updateDoc(
      doc(DB, `services/${payload.serviceId}/chats/${payload.serviceId}`),
      {
        status: 'open'
      }
    )
    dispatch(
      `notification/form/doSendToClient`,
      {
        notification: payload.notification,
        clientId: payload.userId
      },
      { root: true }
    )
  },
  async closeChat({ commit }, payload) {
    const DB = getFirestore()
    await updateDoc(
      doc(DB, `services/${payload.serviceId}/chats/${payload.serviceId}`),
      {
        status: 'closed'
      }
    )
    commit('RESET_MESSAGES')
  },
  doSetIsReading(_, { serviceId, messagesIds }) {
    try {
      const DB = getFirestore()
      messagesIds.forEach(messageId => {
        updateDoc(
          doc(
            DB,
            `services/${serviceId}/chats/${serviceId}/messages/${messageId}`
          ),
          {
            read: true
          }
        )
      })
    } catch (error) {
      console.error(error)
    }
  },
  doSetUnreadMessages(_, serviceId) {
    try {
      const DB = getFirestore()
      updateDoc(doc(DB, `services/${serviceId}/chats/${serviceId}`), {
        unreadMessages: false
      })
    } catch (error) {
      console.error(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
