/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlClient)
const graphqlClientSecurity = GraphqlClient.initApolloClient(
  config.backendUrlGate
)

export class UserService {
  static async fetchUsers(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query USER_LIST_USERS(
          $filter: [Filter]!
          $pagination: PaginationInput!
        ) {
          listClients(filter: $filter, pagination: $pagination) {
            id
            fullName
            isRemoved
            lang
            owned
            membered
            phoneNumber
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        filter,
        pagination
      }
    })

    return response.data.listClients
  }

  static async fetchSecurities(filter, pagination) {
    const response = await graphqlClientSecurity.query({
      query: gql`
        query USER_LIST_USERS(
          $filter: [Filter]!
          $pagination: PaginationInput!
        ) {
          listGates(filter: $filter, pagination: $pagination) {
            createdAt
            createdBy
            email
            id
            isRemoved
            lang
            updatedAt
            updatedBy
            userName
          }
        }
      `,
      variables: {
        filter,
        pagination
      }
    })

    return response.data.listGates
  }

  static async create(clientInput) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_CREATE($clientInput: ClientInput!) {
          createClient(clientInput: $clientInput)
        }
      `,
      variables: {
        clientInput
      }
    })

    if (response.data.createClient && response.data.createClient.status) {
      return response.data.createClient.result
    } else {
      console.error(response.data.createClient.error)
      throw response.data.createClient.error
    }
  }
  static async createSecurity(gateInput) {
    const response = await graphqlClientSecurity.mutate({
      mutation: gql`
        mutation USER_CREATE($gateInput: GateInput!) {
          createGate(gateInput: $gateInput)
        }
      `,
      variables: {
        gateInput
      }
    })

    if (response.data.createGate && response.data.createGate.status) {
      return response.data.createGate.result
    } else {
      console.error(response.data.createGate.error)
      throw response.data.createGate.error
    }
  }

  static async edit(clientId, clientUpdate) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_EDIT($clientId: String!, $clientUpdate: ClientUpdate!) {
          updateClient(clientId: $clientId, clientUpdate: $clientUpdate)
        }
      `,
      variables: {
        clientId,
        clientUpdate
      }
    })

    if (response.data.updateClient && response.data.updateClient.status) {
      return response.data.updateClient.result
    } else {
      console.error(response.data.updateClient.error)
      throw response.data.updateClient.error
    }
  }

  static async editSecurity(gateId, gateUpdate) {
    const response = await graphqlClientSecurity.mutate({
      mutation: gql`
        mutation USER_EDIT($gateId: String!, $gateUpdate: GateUpdate!) {
          editGate(gateId: $gateId, gateUpdate: $gateUpdate)
        }
      `,
      variables: {
        gateId,
        gateUpdate
      }
    })

    if (response.data.editGate && response.data.editGate.status) {
      return response.data.editGate.result
    } else {
      console.error(response.data.editGate.error)
      throw response.data.editGate.error
    }
  }

  static async find(clientId) {
    const response = await graphqlClient.query({
      query: gql`
        query USER_FIND($clientId: String!) {
          findClient(clientId: $clientId) {
            id
            fullName
            lang
            membered
            owned
            points
            phoneNumber
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        clientId
      }
    })

    return response.data.findClient
  }

  static async findSecurity(gateId) {
    const response = await graphqlClientSecurity.query({
      query: gql`
        query USER_FIND($gateId: String!) {
          findGate(gateId: $gateId) {
            createdAt
            createdBy
            email
            id
            isRemoved
            lang
            updatedAt
            updatedBy
            userName
          }
        }
      `,

      variables: {
        gateId
      }
    })

    return response.data.findGate
  }

  static async assignOwnedUnit(clientId, unitId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_ASSIGN_OWNED($clientId: String!, $unitId: String!) {
          assignOwnedUnit(clientId: $clientId, unitId: $unitId)
        }
      `,
      variables: {
        clientId,
        unitId
      }
    })
    if (response.data.assignOwnedUnit && response.data.assignOwnedUnit.status) {
      return response.data.assignOwnedUnit.result
    } else {
      console.error(response.data.assignOwnedUnit.error)
      throw response.data.assignOwnedUnit.error
    }
  }
  static async assignMemberedUnit(clientId, unitId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_ASSIGN_MEMBERED($clientId: String!, $unitId: String!) {
          assignMemberedUnit(clientId: $clientId, unitId: $unitId)
        }
      `,
      variables: {
        clientId,
        unitId
      }
    })
    if (
      response.data.assignMemberedUnit &&
      response.data.assignMemberedUnit.status
    ) {
      return response.data.assignMemberedUnit.result
    } else {
      console.error(response.data.assignMemberedUnit.error)
      throw response.data.assignMemberedUnit.error
    }
  }
  static async removeOwnedUnit(clientId, unitId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_REMOVE_OWNED($clientId: String!, $unitId: String!) {
          removeOwnedUnit(clientId: $clientId, unitId: $unitId)
        }
      `,
      variables: {
        clientId,
        unitId
      }
    })
    if (response.data.removeOwnedUnit && response.data.removeOwnedUnit.status) {
      return response.data.removeOwnedUnit.result
    } else {
      console.error(response.data.removeOwnedUnit.error)
      throw response.data.removeOwnedUnit.error
    }
  }
  static async removeMemberedUnit(clientId, unitId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_REMOVE_MEMBERED($clientId: String!, $unitId: String!) {
          removeMemberedUnit(clientId: $clientId, unitId: $unitId)
        }
      `,
      variables: {
        clientId,
        unitId
      }
    })
    if (
      response.data.removeMemberedUnit &&
      response.data.removeMemberedUnit.status
    ) {
      return response.data.removeMemberedUnit.result
    } else {
      console.error(response.data.removeMemberedUnit.error)
      throw response.data.removeMemberedUnit.error
    }
  }

  static async removeUser(clientId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_DESTROY($clientId: String!) {
          removeClient(clientId: $clientId)
        }
      `,
      variables: {
        clientId
      }
    })
    if (response.data.removeClient && response.data.removeClient.status) {
      return response.data.removeClient.result
    } else {
      console.error(response.data.removeClient.error)
      throw response.data.removeClient.error
    }
  }
  static async uploadFile(fileLink) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation FILE_UPLOAD($fileLink: String!) {
          importUnits(fileLink: $fileLink)
        }
      `,
      variables: {
        fileLink
      }
    })
    if (response.data.importUnits && response.data.importUnits.status) {
      return response.data.importUnits.result
    } else {
      console.error(response.data.importUnits.error)
      throw response.data.importUnits.error
    }
  }

  static async removeSecurity(securityId) {
    const response = await graphqlClientSecurity.mutate({
      mutation: gql`
        mutation USER_DESTROY($securityId: String!) {
          removeGate(securityId: $securityId)
        }
      `,
      variables: {
        securityId
      }
    })
    if (response.data.removeGate && response.data.removeGate.status) {
      return response.data.removeGate.result
    } else {
      console.error(response.data.removeGate.error)
      throw response.data.removeGate.error
    }
  }
  //#region [ Mutation APIs ]
  /**
   * Enable user in authentication table and firestore database
   * @param {String[]} ids
   * @returns
   */
  // static async enable(ids) {
  //   return this._changeStatus(ids, true)
  // }

  /**
   * Disable user in authentication table and firestore database
   * @param {String[]} ids
   * @returns
   */
  // static async disable(ids) {
  //   return this._changeStatus(ids, false)
  // }

  // static async _changeStatus(ids, disabled) {
  //   const response = await graphqlClient.mutate({
  //     mutation: gql`
  //       mutation USER_CHANGE_STATUS(
  //         $adminsIds: [String!]!
  //         $status: Boolean!
  //       ) {
  //         setAdminsStatus(adminsIds: $adminsIds, status: $status)
  //       }
  //     `,

  //     variables: {
  //       adminsIds: ids,
  //       status: !!disabled
  //     }
  //   })

  //   // return response.data.setAdminsStatus
  //   if (response.data.setAdminsStatus && response.data.setAdminsStatus.status) {
  //     return response.data.setAdminsStatus.result
  //   } else {
  //     console.error(response.data.setAdminsStatus.error);
  //     throw response.data.setAdminsStatus.error
  //   }
  // }

  /**
   * Set page access permissions for the admin
   * - setAdminAccess(adminId: String!, pagesAccess: [String]!): JSON!
   * @param {String} id
   * @param {String[]} pagesAccess
   * @returns
   */
  // static async setAdminAccess(id, pagesAccess) {
  //   const response = await graphqlClient.mutate({
  //     mutation: gql`
  //       mutation USER_CHANGE_ROLES(
  //         $adminId: String!
  //         $pagesAccess: [String]!
  //       ) {
  //         setAdminAccess(adminId: $adminId, pagesAccess: $pagesAccess)
  //       }
  //     `,

  //     variables: {
  //       adminId: id,
  //       pagesAccess
  //     }
  //   })

  //   // return response.data.setAdminAccess
  //   if (response.data.setAdminAccess && response.data.setAdminAccess.status) {
  //     return response.data.setAdminAccess.result
  //   } else {
  //     console.error(response.data.setAdminAccess.error);
  //     throw response.data.setAdminAccess.error
  //   }
  // }

  static async removeUsers(clientsIds) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_DESTROY($clientsIds: [String]!) {
          removeClients(clientsIds: $clientsIds)
        }
      `,

      variables: {
        clientsIds
      }
    })
    if (response.data.removeClients && response.data.removeClients.status) {
      return response.data.removeClients.result
    } else {
      console.error(response.data.removeClients.error)
      throw response.data.removeClients.error
    }
  }

  static async removeSecurities(gatesIds) {
    const response = await graphqlClientSecurity.mutate({
      mutation: gql`
        mutation USER_DESTROY($gatesIds: [String]!) {
          removeGates(gatesIds: $gatesIds)
        }
      `,

      variables: {
        gatesIds
      }
    })
    if (response.data.removeGates && response.data.removeGates.status) {
      return response.data.removeGates.result
    } else {
      console.error(response.data.removeGates.error)
      throw response.data.removeGates.error
    }
  }

  // static async remove(emails, roles, all = false) {
  //   const response = await graphqlClient.mutate({
  //     mutation: gql`
  //       mutation USER_REMOVE(
  //         $emails: [String!]!
  //         $roles: [String!]!
  //         $all: Boolean
  //       ) {
  //         iamRemove(
  //           emails: $emails
  //           roles: $roles
  //           all: $all
  //         )
  //       }
  //     `,

  //     variables: {
  //       emails,
  //       roles,
  //       all
  //     }
  //   })

  //   return response.data.iamRemove
  // }

  // static async destroy(id) {
  //   const response = await graphqlClient.mutate({
  //     mutation: gql`
  //       mutation USER_DESTROY($id: String!) {
  //         iamDestroy(id: $id)
  //       }
  //     `,

  //     variables: {
  //       id
  //     }
  //   })
  //   return response.data.iamDestroy
  // }
  //#endregion

  //#region [ Query APIs ]

  /**
   * Retrieve admins with pagination
   * @param {Object[]} filter
   * @param {String} filter.key
   * @param {String} filter.value
   * @param {'less'|'lessEqual'|'greater'|'greaterEqual'|'equal'|'notEqual'|'startsWith'} filter.operator
   * @param {Object} pagination
   * @param {Boolean} pagination.firstPage
   * @param {Int} pagination.limit
   * @param {JSON} pagination.doc
   * @param {'next'|'prev'} pagination.action
   * @param {'asc'|'desc'} pagination.sortType
   * @param {String} pagination.orderBy
   * @returns
   */
  // static async fetchAdmins(filter, pagination) {
  //   const response = await graphqlClient.query({
  //     query: gql`
  //       query USER_LIST_USERS(
  //         $filter: [Filter]!
  //         $pagination: PaginationInput!
  //       ) {
  //         listAdmins(filter: $filter, pagination: $pagination) {
  //           id
  //           email
  //           userName
  //           phoneNumber
  //           avatar
  //           pagesAccess
  //           role
  //           deviceTokens
  //           status
  //           lang
  //           createdBy
  //           createdAt
  //           updatedBy
  //           updatedAt
  //         }
  //       }
  //     `,

  //     variables: {
  //       filter,
  //       pagination
  //     }
  //   })

  //   return response.data.listAdmins
  // }

  /**
   * Retrieve users with pagination without admins
   * @param {Object[]} filter
   * @param {String} filter.key
   * @param {String} filter.value
   * @param {'less'|'lessEqual'|'greater'|'greaterEqual'|'equal'|'notEqual'|'startsWith'} filter.operator
   * @param {Object} pagination
   * @param {Boolean} pagination.firstPage
   * @param {Int} pagination.limit
   * @param {JSON} pagination.doc
   * @param {'next'|'prev'} pagination.action
   * @param {'asc'|'desc'} pagination.sortType
   * @param {String} pagination.orderBy
   * @returns
   */

  /**
   * Find list of user addresses
   * - listUserAddresses(userId: String!): [Address]!
   * @param {String} id - User ID
   * @returns {Promise<Address[]>}
   */
  // static async listUserAddresses(id) {
  //   const response = await graphqlClient.query({
  //     query: gql`
  //       query USER_LIST_ADDRESSES($userId: String!) {
  //         listUserAddresses(userId: $userId) {
  //           id
  //           name
  //           description
  //           lat
  //           long
  //           phoneNumber
  //           isDefault
  //           city {
  //             id
  //             en
  //             ar
  //           }
  //         }
  //       }
  //     `,

  //     variables: {
  //       userId: id
  //     }
  //   })

  //   return response.data.listUserAddresses
  // }
  //#endregion
}
