/* eslint-disable */
import { ProjectService } from '@/store/project/project-service'
import Errors from '@/shared/error/errors'
import Message from '@/shared/message/toastify'
import { i18n } from '@/locales/i18n'

const INITIAL_PAGE_SIZE = 10
const INITIAL_PAGINATION_INPUT = {
  limit: INITIAL_PAGE_SIZE,
  orderBy: 'createdAt',
  sortType: 'desc',
  action: 'next',
  firstPage: true,
  doc: {}
}

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    filter: [],
    currentPage: 1,
    pagination: INITIAL_PAGINATION_INPUT
  },

  getters: {
    loading: state => state.loading,
    rows: state => state.rows || [],
    count: state => state.count,
    hasRows: (state, getters) => getters.count > 0,
    filter: state => state.filter,
    currentPage: state => state.currentPage,
    limit: state => {
      const pagination = state.pagination
      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE
      }
      return pagination.pageSize
    },
    pagination: (state, getters) => {
      return {
        ...state.pagination,
        currentPage: state.currentPage,
        total: getters.count,
        showSizeChanger: true
      }
    },
    selectedRows: state => {
      return state.table ? state.table.selection : []
    }
  },

  mutations: {
    RESETED(state) {
      state.rows = []
      state.count = 0
      state.loading = false
      state.filter = []
      state.pagination = INITIAL_PAGINATION_INPUT
      state.currentPage = 1
    },
    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || INITIAL_PAGINATION_INPUT
    },
    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      state.currentPage =
        payload && payload > 0 ? payload : state.currentPage || 1
    },
    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || INITIAL_PAGINATION_INPUT
      state.currentPage = state.currentPage || 1
      state.pagination = {
        ...previousPagination,
        limit: payload || INITIAL_PAGE_SIZE
      }
    },
    FETCH_STARTED(state, payload) {
      state.loading = true
      state.filter = payload && payload.filter ? payload.filter : state.filter
      state.pagination =
        payload && payload.pagination
          ? payload.pagination
          : payload.keepPagination
          ? state.pagination
          : {}
    },
    FETCH_SUCCESS(state, payload) {
      state.loading = false
      state.rows = payload.rows
      state.count = payload.count
    },
    FETCH_ERROR(state) {
      state.loading = false
      state.rows = []
      state.count = 0
    },
    DESTROY_STARTED(state) {
      state.loading = true
    },
    DESTROY_SUCCESS(state) {
      state.loading = false
    },
    DESTROY_ERROR(state) {
      state.loading = false
    }
  },

  actions: {
    async doReset({ commit, dispatch }) {
      commit('RESETED')
      return dispatch('doFetch')
    },
    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize)
      const filter = getters.filter
      const pagination = getters.pagination
      dispatch('doFetch', { filter, pagination, keepPagination: true })
    },
    doChangePaginationCurrentPage({ commit }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage)
    },
    async doFetchNextPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      if (rows.length) {
        const currentPage = getters.currentPage || 1
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const lastDocument = rows[rows.length - 1]
        const pagination = {
          ...previousPagination,
          action: 'next',
          firstPage: false,
          doc: lastDocument
        }
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage + 1)
        await dispatch('doFetch', { pagination })
      }
    },
    async doFetchPreviousPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      const currentPage = getters.currentPage || 1
      if (currentPage > 1 && rows.length) {
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const firstDocument = rows[0]
        const pagination = {
          ...previousPagination,
          action: 'prev',
          firstPage: false,
          doc: firstDocument
        }
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage - 1)
        await dispatch('doFetch', { pagination })
      }
    },
    async doFetch(
      { commit, getters },
      { filter, pagination, keepPagination = true } = {}
    ) {
      try {
        commit('FETCH_STARTED', { filter, pagination, keepPagination })
        const PaginationInput = {
          limit: pagination.limit || getters.pagination.limit,
          doc: pagination.doc || getters.pagination.doc,
          action: pagination.action || getters.pagination.action,
          orderBy: pagination.orderBy || getters.pagination.orderBy,
          sortType: pagination.sortType || getters.pagination.sortType,
          firstPage: pagination.firstPage
        }
        const response = await ProjectService.list(
          getters.filter,
          PaginationInput
        )

        commit('FETCH_SUCCESS', {
          rows: response,
          count: response.length
        })
      } catch (error) {
        Errors.handle(error)
        commit('FETCH_ERROR')
      }
    },
    async doDestroy({ commit, dispatch, getters }, id) {
      try {
        commit('DESTROY_STARTED')
        await ProjectService.remove(id)
        commit('DESTROY_SUCCESS')
        Message.success(i18n('project.destroy.success'))
        const pagination = getters.pagination || INITIAL_PAGINATION_INPUT
        await dispatch('doFetch', { pagination })
      } catch (error) {
        Errors.handle(error)
        commit('DESTROY_ERROR')
      }
    }
  }
}
