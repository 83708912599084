const firebaseConfig = {
  apiKey: 'AIzaSyBHq8SEFdIYncZHDOUg3RZUZF4aXFSmNeA',
  authDomain: 'el-ghoneimy-service.firebaseapp.com',
  projectId: 'el-ghoneimy-service',
  storageBucket: 'el-ghoneimy-service.appspot.com',
  messagingSenderId: '52423920730',
  appId: '1:52423920730:web:665188688ca4768b2fdc63',
  measurementId: 'G-3V597YWWD0'
}

const REGION = 'europe-west1'
const BASE_URL = `https://${REGION}-${firebaseConfig.projectId}.cloudfunctions.net`

// #region [ Cloud Functions ]
const backendUrl = BASE_URL
const backendUrlAdmin = `${BASE_URL}/admin`
const backendUrlSlider = `${BASE_URL}/slider`
const backendUrlSettings = `${BASE_URL}/settings`
const backendUrlClient = `${BASE_URL}/client`
const backendUrlLead = `${BASE_URL}/lead`
const backendUrlProject = `${BASE_URL}/project`
const backendUrlTemplate = `${BASE_URL}/unitTemplate`
const backendUrlUnit = `${BASE_URL}/unit`
const backendUrlEvent = `${BASE_URL}/event`
const backendUrlServiceTypes = `${BASE_URL}/serviceTypes`
const backendUrlService = `${BASE_URL}/service`
const backendUrlFeedBack = `${BASE_URL}/feedBack`
const backendUrlMaintenance = `${BASE_URL}/maintenance`
const backendUrlMaintenanceTypes = `${BASE_URL}/maintenanceTypes`
const backendUrlNotification = `${BASE_URL}/notification`
const backendUrlSecurity = `${BASE_URL}/security`
const backendUrlGate = `${BASE_URL}/gate`

export default {
  firebaseConfig,
  REGION,
  BASE_URL,
  backendUrl,
  backendUrlAdmin,
  backendUrlSlider,
  backendUrlSettings,
  backendUrlLead,
  backendUrlProject,
  backendUrlTemplate,
  backendUrlUnit,
  backendUrlClient,
  backendUrlEvent,
  backendUrlServiceTypes,
  backendUrlService,
  backendUrlFeedBack,
  backendUrlMaintenance,
  backendUrlNotification,
  backendUrlSecurity,
  backendUrlMaintenanceTypes,
  backendUrlGate
}
