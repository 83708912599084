/* eslint-disable */
import SecurityListStore from '@/store/security/security-list-store'

export default {
  namespaced: true,

  modules: {
    list: SecurityListStore
  }
}
