import config from '@/config'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getDatabase } from 'firebase/database'

const firebaseInit = () => {
  const app = initializeApp(config.firebaseConfig)

  getAuth(app)
  getFirestore(app)
  getStorage(app)
  getDatabase(app)
}

export default firebaseInit
