/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'

//#region [ Main Pages ]
const SideMenu = () => import('@/layouts/side-menu/Main.vue')
const ErrorPage = () => import('@/views/error-page/Main.vue')
const Error403Page = () => import('@/views/error-page/error-403-page.vue')
const Error404Page = () => import('@/views/error-page/error-404-page.vue')
const Error500Page = () => import('@/views/error-page/error-500-page.vue')
//#endregion

const Login = () => import('@/views/auth/signin-page.vue')
const ForgotPassword = () => import('@/views/auth/forgot-password.vue')
const Profile = () => import('@/views/profile/profile.vue')
const ChangePassword = () => import('@/views/profile/change-password.vue')

const AdminListPape = () => import('@/views/admin/admin-list-page.vue')
const AdminFormPage = () => import('@/views/admin/admin-form-page.vue')
const AdminViewPage = () => import('@/views/admin/admin-view-page.vue')
const AdminAccessPage = () => import('@/views/admin/admin-access-page.vue')

const UsersListPage = () => import('@/views/users/user-list-page.vue')
const UsersFormPage = () => import('@/views/users/user-form-page.vue')
const UserViewPage = () => import('@/views/users/user-view-page.vue')
const UsersAssignPage = () => import('@/views/users/user-assign-page.vue')

const SecuritiesListPage = () =>
  import('@/views/securities/securities-list-page.vue')
const SecuritiesFormPage = () =>
  import('@/views/securities/securities-form-page.vue')

const ServicesTypeListPage = () =>
  import('@/views/services-type/services-type-list-page.vue')
const ServicesTypeFormPage = () =>
  import('@/views/services-type/services-type-form-page.vue')

const ServiceListPage = () => import('@/views/service/service-list-page.vue')
const ServiceChatPage = () => import('@/views/service/service-chat-page.vue')

const MaintenanceTypeListPage = () =>
  import('@/views/maintenance-type/maintenance-type-list-page.vue')
const MaintenanceTypeFormPage = () =>
  import('@/views/maintenance-type/maintenance-type-form-page.vue')

const MaintenanceListPage = () =>
  import('@/views/maintenance/maintenance-list-page.vue')
const MaintenanceChatPage = () =>
  import('@/views/maintenance/maintenance-chat-page.vue')

const FeedbackListPage = () => import('@/views/feedback/feedback-list-page.vue')

const NotificationListPage = () =>
  import('@/views/notification/notification-list-page.vue')
const NotificationCreate = () =>
  import('@/views/notification/notification-create.vue')

const SliderListPage = () => import('@/views/slider/slider-list-page.vue')
const SliderViewPage = () => import('@/views/slider/slider-view-page.vue')
const SliderFormPage = () => import('@/views/slider/slider-form-page.vue')

const EventListPage = () => import('@/views/event/event-list-page.vue')
const EventViewPage = () => import('@/views/event/event-view-page.vue')
const EventFormPage = () => import('@/views/event/event-form-page.vue')

const Complaint = () => import('@/views/complaint/complaint-page.vue')
const Settings = () => import('@/views/settings/settings-list-page.vue')

const salesRequestsListPage = () =>
  import('@/views/sales-requests/sales-requests-list-page.vue')
const ProjectsListPage = () => import('@/views/projects/projects-list-page.vue')
const ProjectFormPage = () => import('@/views/projects/projects-form-page.vue')
const ProjectViewPage = () => import('@/views/projects/projects-view-page.vue')

const TemplateFormPage = () =>
  import('@/views/unit-template/unit-template-form-page.vue')
const TemplateViewPage = () =>
  import('@/views/unit-template/unit-template-view-page.vue')

const UnitListPage = () => import('@/views/units/units-list-page.vue')
const UnitFormPage = () => import('@/views/units/units-form-page.vue')
const UnitViewPage = () => import('@/views/units/units-view-page.vue')

const SecurityListPage = () => import('@/views/security/security-list-page.vue')

const Policies = () => import('@/views/policies/policies-page.vue')

import Permissions from '@/security/permissions'

const routes = [
  {
    path: '',
    component: SideMenu,
    meta: { auth: true },
    children: [
      {
        name: 'profile',
        path: 'profile',
        component: Profile,
        meta: {
          auth: true
        }
      },
      {
        name: 'changePassword',
        path: 'change-password',
        component: ChangePassword,
        meta: {
          auth: true
        }
      },
      {
        name: 'admins',
        path: '',
        alias: 'admins',
        component: AdminListPape,
        meta: {
          auth: true
          // permission: Permissions.values.adminRead
        }
      },
      {
        name: 'adminNew',
        path: 'admins/new',
        component: AdminFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminCreate
        }
      },
      {
        name: 'adminEdit',
        path: 'admins/:id/edit',
        props: true,
        component: AdminFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminEdit
        }
      },
      {
        name: 'adminView',
        path: 'admins/:id',
        component: AdminViewPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminRead
        }
      },
      {
        name: 'adminAccessPages',
        path: 'admins/:id/access-pages',
        component: AdminAccessPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.pagesAccessRead
        }
      },
      {
        name: 'sales-requests',
        path: 'sales-requests',
        component: salesRequestsListPage,
        meta: {
          auth: true
          // permission: Permissions.values.salesRequestsRead
        }
      },
      {
        name: 'projects',
        path: 'projects',
        component: ProjectsListPage,
        meta: {
          auth: true
          // permission: Permissions.values.salesRequestsRead
        }
      },
      {
        name: 'projectNew',
        path: 'projects/new',
        component: ProjectFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.projectCreate
        }
      },
      {
        name: 'projectEdit',
        path: 'projects/:id/edit',
        props: true,
        component: ProjectFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.projectEdit
        }
      },
      {
        name: 'projectView',
        path: 'projects/:id',
        component: ProjectViewPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.projectRead
        }
      },
      {
        name: 'templateNew',
        path: 'projects/:projectId/unit-template/new',
        component: TemplateFormPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.projectCreate
        }
      },
      {
        name: 'templateEdit',
        path: 'projects/:projectId/unit-template/:id/edit',
        props: true,
        component: TemplateFormPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.projectEdit
        }
      },
      {
        name: 'templateView',
        path: 'unit-template/:id',
        component: TemplateViewPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.projectRead
        }
      },
      {
        name: 'units',
        path: 'units',
        component: UnitListPage,
        meta: {
          auth: true
          // permission: Permissions.values.salesRequestsRead
        }
      },
      {
        name: 'unitNew',
        path: 'units/new',
        component: UnitFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.unitCreate
        }
      },
      {
        name: 'unitEdit',
        path: 'units/:id/edit',
        props: true,
        component: UnitFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.projectEdit
        }
      },
      {
        name: 'unitView',
        path: 'units/:id',
        props: true,
        component: UnitViewPage,
        meta: {
          auth: true
          // permission: Permissions.values.userRead
        }
      },
      {
        name: 'users',
        path: 'users',
        component: UsersListPage,
        meta: {
          auth: true
          // permission: Permissions.values.userRead
        }
      },
      {
        name: 'userNew',
        path: 'users/new',
        component: UsersFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminCreate
        }
      },
      {
        name: 'userEdit',
        path: 'users/:id/edit',
        props: true,
        component: UsersFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminEdit
        }
      },
      {
        name: 'userView',
        path: 'users/:id',
        props: true,
        component: UserViewPage,
        meta: {
          auth: true
          // permission: Permissions.values.userRead
        }
      },
      {
        name: 'userAssign',
        path: 'users/:id/:type',
        props: true,
        component: UsersAssignPage,
        meta: {
          auth: true
          // permission: Permissions.values.userRead
        }
      },
      {
        name: 'securities',
        path: 'securities',
        component: SecuritiesListPage,
        meta: {
          auth: true
          // permission: Permissions.values.userRead
        }
      },
      {
        name: 'securitiesNew',
        path: 'securities/new',
        component: SecuritiesFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminCreate
        }
      },
      {
        name: 'securitiesEdit',
        path: 'securities/:id/edit',
        props: true,
        component: SecuritiesFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminEdit
        }
      },
      // {
      //   name: 'category',
      //   path: 'category',
      //   component: CategoryListPage,
      //   meta: {
      //     auth: true,
      // permission: Permissions.values.categoryRead
      //   }
      // },
      // {
      //   name: 'categoryNew',
      //   path: 'category/new',
      //   component: CategoryFormPage,
      //   meta: {
      //     auth: true,
      // permission: Permissions.values.categoryCreate
      //   },
      // },
      // {
      //   name: 'categoryEdit',
      //   path: 'category/:id/edit',
      //   props: true,
      //   component: CategoryFormPage,
      //   meta: {
      //     auth: true,
      // permission: Permissions.values.categoryEdit
      //   }
      // },
      // {
      //   name: 'subcategory',
      //   path: 'category/:categoryId/subcategory',
      //   props: true,
      //   component: SubcategoryListPage,
      //   meta: {
      //     auth: true,
      // permission: Permissions.values.subcategoryRead
      //   }
      // },
      // {
      //   name: 'subcategoryNew',
      //   path: 'category/:categoryId/subcategory/new',
      //   props: true,
      //   component: SubcategoryFormPage,
      //   meta: {
      //     auth: true,
      // permission: Permissions.values.subcategoryCreate,
      //   },
      // },
      // {
      //   name: 'subcategoryEdit',
      //   path: 'category/:categoryId/subcategory/:id/edit',
      //   props: true,
      //   component: SubcategoryFormPage,
      //   meta: {
      //     auth: true,
      // permission: Permissions.values.subcategoryEdit
      //   }
      // },
      // {
      //   name: 'subcategoryView',
      //   path: 'category/:categoryId/subcategory/:id',
      //   props: true,
      //   component: SubcategoryViewPage,
      //   meta: {
      //     auth: true,
      // permission: Permissions.values.subcategoryRead
      //   }
      // },
      {
        name: 'servicesType',
        path: 'servicesType',
        component: ServicesTypeListPage,
        meta: {
          auth: true
          // permission: Permissions.values.servicesTypeRead
        }
      },
      {
        name: 'servicesTypeNew',
        path: 'servicesType/new',
        component: ServicesTypeFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.servicesTypeCreate
        }
      },
      {
        name: 'servicesTypeEdit',
        path: 'servicesType/:id/edit',
        props: true,
        component: ServicesTypeFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.serviceTypeEdit
        }
      },
      {
        name: 'service',
        path: 'service',
        component: ServiceListPage,
        meta: {
          auth: true
          // permission: Permissions.values.servicesTypeRead
        }
      },
      {
        name: 'serviceChat',
        path: 'service/:serviceId/chat',
        component: ServiceChatPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.servicesTypeRead
        }
      },
      {
        name: 'security',
        path: 'security',
        component: SecurityListPage,
        meta: {
          auth: true
          // permission: Permissions.values.servicesTypeRead
        }
      },
      {
        name: 'maintenanceType',
        path: 'maintenanceType',
        component: MaintenanceTypeListPage,
        meta: {
          auth: true
          // permission: Permissions.values.maintenanceTypeRead
        }
      },
      {
        name: 'maintenanceTypeNew',
        path: 'maintenanceType/new',
        component: MaintenanceTypeFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.maintenanceTypeCreate
        }
      },
      {
        name: 'maintenanceTypeEdit',
        path: 'maintenanceType/:id/edit',
        props: true,
        component: MaintenanceTypeFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.serviceTypeEdit
        }
      },
      {
        name: 'maintenance',
        path: 'maintenance',
        component: MaintenanceListPage,
        meta: {
          auth: true
          // permission: Permissions.values.maintenanceTypeRead
        }
      },
      {
        name: 'maintenanceChat',
        path: 'maintenance/:maintenanceId/chat',
        component: MaintenanceChatPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.servicesTypeRead
        }
      },
      {
        name: 'feedback',
        path: 'feedback',
        component: FeedbackListPage,
        meta: {
          auth: true
          // permission: Permissions.values.servicesTypeRead
        }
      },
      // {
      //   name: 'products',
      //   path: 'products',
      //   component: ProductListPage,
      //   meta: {
      //     auth: true,
      // permission: Permissions.values.productRead
      //   }
      // },
      // {
      //   name: 'productView',
      //   path: 'products/:id/:sellerId?/:status?',
      //   props: true,
      //   component: ProductViewPage,
      //   meta: {
      //     auth: true,
      // permission: Permissions.values.productRead,
      //   }
      // },
      // {
      //   name: 'orders',
      //   path: 'orders',
      //   component: OrderListPage,
      //   meta: {
      //     auth: true,
      // permission: Permissions.values.orderRead
      //   }
      // },
      // {
      //   name: 'orderView',
      //   path: 'orders/:id',
      //   props: true,
      //   component: OrderViewPage,
      //   meta: {
      //     auth: true,
      // permission: Permissions.values.orderRead
      //   }
      // },
      // {
      //   name: 'payment',
      //   path: 'payment',
      //   component: Payments,
      //   meta: {
      //     auth: true,
      // permission: [
      //       Permissions.values.requestRead,
      //       Permissions.values.walletRead
      //     ],
      //   }
      // },
      // {
      //   name: 'walletView',
      //   path: 'payment/wallet/:id',
      //   props: true,
      //   component: WalletViewPage,
      //   meta: {
      //     auth: true,
      // permission: Permissions.values.walletRead
      //   }
      // },
      // {
      //   name: 'InvoiceView',
      //   path: 'invoices/:id',
      //   component: InvoiceViewPage,
      //   meta: {
      //     auth: true,
      // permission: Permissions.values.invoiceRead
      //   }
      // },
      {
        name: 'notification',
        path: 'notification',
        component: NotificationListPage,
        meta: {
          auth: true
          // permission: Permissions.values.notificationRead
        }
      },
      {
        name: 'notificationNew',
        path: 'notification/new',
        component: NotificationCreate,
        meta: {
          auth: true
          // permission: Permissions.values.notificationCreate
        }
      },
      // {
      //   name: 'promoCode',
      //   path: 'promo-code',
      //   component: PromoCodeListPage,
      //   meta: {
      //     auth: true,
      // permission: Permissions.values.promoCodeRead
      //   }
      // },
      // {
      //   name: 'promoCodeNew',
      //   path: 'promo-code/new',
      //   component: PromoCodeFormPage,
      //   meta: {
      //     auth: true,
      // permission: Permissions.values.promoCodeCreate
      //   }
      // },
      {
        name: 'slider',
        path: 'slider',
        component: SliderListPage,
        meta: {
          auth: true
          // permission: Permissions.values.sliderRead
        }
      },
      {
        name: 'sliderNew',
        path: 'slider/new',
        component: SliderFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.sliderCreate
        }
      },
      {
        name: 'sliderEdit',
        path: 'slider/:id/edit',
        props: true,
        component: SliderFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.sliderEdit
        }
      },
      {
        name: 'sliderView',
        path: 'slider/:id',
        props: true,
        component: SliderViewPage,
        meta: {
          auth: true
          // permission: Permissions.values.sliderRead
        }
      },
      {
        name: 'event',
        path: 'event',
        component: EventListPage,
        meta: {
          auth: true
          // permission: Permissions.values.eventRead
        }
      },
      {
        name: 'eventNew',
        path: 'event/new',
        component: EventFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.eventCreate
        }
      },
      {
        name: 'eventEdit',
        path: 'event/:id/edit',
        props: true,
        component: EventFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.eventEdit
        }
      },
      {
        name: 'eventView',
        path: 'event/:id',
        props: true,
        component: EventViewPage,
        meta: {
          auth: true
          // permission: Permissions.values.eventRead
        }
      },
      // {
      //   name: 'reports',
      //   path: 'reports',
      //   component: Reports,
      //   meta: {
      //     auth: true,
      // permission: Permissions.values.reportRead
      //   }
      // },
      {
        name: 'complaint',
        path: 'complaint',
        component: Complaint,
        meta: {
          auth: true
          // permission: Permissions.values.complaintRead
        }
      },
      {
        name: 'settings',
        path: 'settings',
        component: Settings,
        meta: {
          auth: true
          // permission: Permissions.values.settingRead
        }
      }
    ]
  },
  {
    name: 'login',
    path: '/auth/login',
    component: Login,
    meta: { unauth: true }
  },
  {
    name: 'forgotPassword',
    path: '/auth/forgot-password',
    component: ForgotPassword,
    meta: { unauth: true }
  },
  {
    name: 'termsAndConditions',
    path: '/:lang/terms-and-conditions',
    component: Policies,
    // meta: { unauth: true }
  },
  {
    name: 'privacyPolicy',
    path: '/:lang/privacy-policy',
    component: Policies,
    // meta: { unauth: true }
  },
  {
    name: 'errorPage',
    path: '/error-page',
    component: ErrorPage
  },
  {
    name: 'error403',
    path: '/403',
    component: Error403Page
  },
  {
    name: 'error404',
    path: '/404',
    component: Error404Page
  },
  {
    name: 'error500',
    path: '/500',
    component: Error500Page
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  }
  // { path: '*', redirect: '/404' }
]

//
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

// eslint-disable-next-line
import authGuard from '@/router/auth-guard'
router.beforeResolve(async (to, from, next) => {
  await authGuard.beforeRouteEnter(to, from, next)
})

// import authGuardMixin from '@/auth/guards/auth-guard-mixin'
// import unauthGuardMixin from '@/auth/guards/unauth-guard-mixin'
// import notEmptyPermissionsGuardMixin from '@/auth/guards/not-empty-permissions-guard-mixin'
// // import { mixins } from '@/auth/guards'
// router.beforeEach((to, from, next) => {
//   // if (to.name) {
//   //   ProgressBar.start();
//   // }

//   next()
// })

// router.beforeResolve(async (to, from, next) => {
//   await authGuardMixin.beforeRouteEnter(to, from, next)
//   await unauthGuardMixin.beforeRouteEnter(to, from, next)
//   await notEmptyPermissionsGuardMixin.beforeRouteEnter(to, from, next)
// })
// router.beforeEach(async (to, from, next) => {
//
//
//   next()
//   // await authGuardMixin.beforeRouteEnter(to, from, next)
//   // await unauthGuardMixin.beforeRouteEnter(to, from, next)
//   // await notEmptyPermissionsGuardMixin.beforeRouteEnter(to, from, next)

//   // for (let index = 0; index < guardMixin.length; index++) {
//   //   const mixin = guardMixin[index]
//   //   await mixin.beforeRouteEnter(to, from, next)
//   // }
//   // mixins.forEach(async mixin => {
//   //   await mixin.beforeRouteEnter(to, from, next)
//   // });
// })

// router.beforeEach(async (to, from, next) => {
//
//
//   // next()
//   await authGuardMixin.beforeRouteEnter(to, from, next)
// })
// router.beforeEach(async (to, from, next) => {
//
//
//   next()
//   await unauthGuardMixin.beforeRouteEnter(to, from, next)
// })
// router.beforeEach(async (to, from, next) => {
//
//
//   next()
//   await notEmptyPermissionsGuardMixin.beforeRouteEnter(to, from, next)
// })

export function getPages(pages = routes) {
  const routesPages = []
  for (let i = 0; i < pages.length; i++) {
    const route = pages[i]
    if (route.name) {
      routesPages.push({ name: route.name, checked: false })
    }

    if (route.children && route.children.length) {
      const result = getPages(route.children, 0)
      routesPages.push(...result)
    }
  }
  return routesPages
}
export default router
