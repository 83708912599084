import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlProject)

export class ProjectService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query PROJECT_LIST($filter: [Filter]!, $pagination: PaginationInput!) {
          listProjects(filter: $filter, pagination: $pagination) {
            id
            address {
              lat
              long
              name {
                en
                ar
              }
            }
            name {
              ar
              en
            }
            description {
              ar
              en
            }
            isRemoved
            projectType
            images
            createdBy
            createdAt
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        filter,
        pagination
      }
    })
    return response.data.listProjects
  }

  static async create(projectInput) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PROJECT_CREATE($projectInput: ProjectInput!) {
          createProject(projectInput: $projectInput)
        }
      `,
      variables: {
        projectInput
      }
    })
    if (response.data.createProject && response.data.createProject.status) {
      return response.data.createProject.result
    } else {
      throw response.data.createProject.error
    }
  }

  static async update(projectId, projectUpdate) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PROJECT_UPDATE(
          $projectId: String!
          $projectUpdate: ProjectUpdate!
        ) {
          editProject(projectId: $projectId, projectUpdate: $projectUpdate)
        }
      `,
      variables: {
        projectId,
        projectUpdate
      }
    })
    if (response.data.editProject && response.data.editProject.status) {
      return response.data.editProject.result
    } else {
      throw response.data.editProject.error
    }
  }

  static async find(projectId) {
    const response = await graphqlClient.query({
      query: gql`
        query PROJECT_FIND($projectId: String!) {
          findProject(projectId: $projectId) {
            id
            address {
              lat
              long
              name {
                en
                ar
              }
            }
            name {
              ar
              en
            }
            description {
              ar
              en
            }
            isRemoved
            projectType
            images
            createdBy
            createdAt
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        projectId
      }
    })

    return response.data.findProject
  }

  static async remove(projectId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PROJECT_DESTROY($projectId: String!) {
          removeProject(projectId: $projectId)
        }
      `,
      variables: {
        projectId
      }
    })
    if (response.data.removeProject && response.data.removeProject.status) {
      return response.data.removeProject.result
    } else {
      console.error(response.data.removeProject.error)
      throw response.data.removeProject.error
    }
  }
}
