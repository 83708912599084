/* eslint-disable */
import MaintenanceTypeListStore from '@/store/maintenance-type/maintenance-type-list-store'
import MaintenanceTypeFormStore from '@/store/maintenance-type/maintenance-type-form-store'
import MaintenanceTypeDestroyStore from '@/store/maintenance-type/maintenance-type-destroy-store'

export default {
  namespaced: true,

  modules: {
    list: MaintenanceTypeListStore,
    form: MaintenanceTypeFormStore,
    destroy: MaintenanceTypeDestroyStore
  }
}
