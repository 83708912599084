/* eslint-disable */

import AdminService from '@/store/auth/auth-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'
// import PermissionChecker from '@/security/permission-checker'
// import Permissions from '@/security/permissions'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null
  },

  getters: {
    record: state => state.record,
    findLoading: state => !!state.findLoading,
    saveLoading: state => !!state.saveLoading
  },

  mutations: {
    RESET(state) {
      state.findLoading = false
      state.saveLoading = false
      state.record = null
    },

    FIND_STARTED(state) {
      state.record = null
      state.findLoading = true
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload
      state.findLoading = false
    },

    FIND_ERROR(state) {
      state.record = null
      state.findLoading = false
    },

    ADD_STARTED(state) {
      state.saveLoading = true
    },

    ADD_SUCCESS(state) {
      state.saveLoading = false
    },

    ADD_ERROR(state) {
      state.saveLoading = false
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET')
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED')
        const record = await AdminService.find(id)

        commit('FIND_SUCCESS', record)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ERROR')
        router.push('/admins')
      }
    },

    async doAdd({ commit, rootGetters }, values) {
      try {
        commit('ADD_STARTED')
        const record = await AdminService.create(values)
        commit('ADD_SUCCESS')
        Message.success(i18n('admin.create.success'))

        // const currentUser = rootGetters['auth/currentUser']
        // const hasPermissionToAccessPages = new PermissionChecker(
        //   currentUser
        // ).match(Permissions.values.pagesAccessRead)

        // if (hasPermissionToAccessPages) {
        //   router.push(`/admins/${record.id}/access-pages`)
        // } else {
        router.push('/admins')
        // }
      } catch (error) {
        Errors.handle(error)
        commit('ADD_ERROR')
      }
    },

    async doUpdate({ commit, rootGetters, dispatch }, { id, values }) {
      try {
        commit('UPDATE_STARTED')
        await AdminService.edit(id, values)
        commit('UPDATE_SUCCESS')
        const currentUser = rootGetters['auth/currentUser']
        if (currentUser.id === id) {
          dispatch('auth/doRefreshCurrentUser')
        }
        Message.success(i18n('admin.update.success'))
        router.push('/admins')
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    }

    // async doSetAdminAccess(
    //   { commit, rootGetters, dispatch },
    //   { id, pagesAccess }
    // ) {
    //   try {
    //     commit('UPDATE_STARTED')
    //     await AdminService.setAdminAccess(id, pagesAccess)
    //     commit('UPDATE_SUCCESS')
    //     const currentUser = rootGetters['auth/currentUser']
    //     if (currentUser.id === id) {
    //       dispatch('auth/doRefreshCurrentUser')
    //     }
    //     Message.success(i18n('admin.update.success'))
    //     router.push('/admins')
    //   } catch (error) {
    //     Errors.handle(error)
    //     commit('UPDATE_ERROR')
    //   }
    // }
  }
}
