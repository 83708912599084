/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(
  config.backendUrlServiceTypes
)

export class ServicesTypeService {
  static async create(serviceTypeInput) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CREATE_SERVICES_TYPE($serviceTypeInput: ServiceTypeInput!) {
          createServiceType(serviceTypeInput: $serviceTypeInput)
        }
      `,

      variables: {
        serviceTypeInput
      }
    })

    if (
      response.data.createServiceType &&
      response.data.createServiceType.status
    ) {
      return response.data.createServiceType.result
    } else {
      console.error(response.data.createServiceType.error)
      throw response.data.createServiceType.error
    }
  }
  static async find(serviceTypeId) {
    const response = await graphqlClient.query({
      query: gql`
        query SERVICES_TYPE_FIND($serviceTypeId: String!) {
          findServiceType(serviceTypeId: $serviceTypeId) {
            id
            name {
              ar
              en
            }
            logo
            createdBy
            createdAt
            isEnabled
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        serviceTypeId
      }
    })

    return response.data.findServiceType
  }

  static async list(pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query SERVICES_TYPE_LIST(
          $filter: [Filter]!
          $pagination: PaginationInput!
        ) {
          listServicesTypes(filter: $filter, pagination: $pagination) {
            id
            name {
              en
              ar
            }
            logo
            isRemoved
            isEnabled
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        filter: [],
        pagination
      }
    })

    return response.data.listServicesTypes
  }
  static async update(serviceTypeId, serviceTypeInput) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SERVICES_UPDATE(
          $serviceTypeId: String!
          $serviceTypeInput: ServiceTypeInput!
        ) {
          editServiceType(
            serviceTypeId: $serviceTypeId
            serviceTypeInput: $serviceTypeInput
          )
        }
      `,
      variables: {
        serviceTypeId,
        serviceTypeInput
      }
    })
    if (response.data.editServiceType && response.data.editServiceType.status) {
      return response.data.editServiceType.result
    } else {
      console.error(response.data.editServiceType.error)
      throw response.data.editServiceType.error
    }
  }

  static async setStatus(serviceTypeId, status) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SERVICES_TYPE_STATUS(
          $serviceTypeId: String!
          $status: Boolean!
        ) {
          setServiceTypeStatus(serviceTypeId: $serviceTypeId, status: $status)
        }
      `,
      variables: {
        serviceTypeId,
        status
      }
    })
    if (
      response.data.setServiceTypeStatus &&
      response.data.setServiceTypeStatus.status
    ) {
      return response.data.setServiceTypeStatus.result
    } else {
      console.error(response.data.setServiceTypeStatus.error)
      throw response.data.setServiceTypeStatus.error
    }
  }

  static async remove(serviceTypeId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SERVICES_TYPE_DESTROY($serviceTypeId: String!) {
          removeServiceType(serviceTypeId: $serviceTypeId)
        }
      `,

      variables: {
        serviceTypeId
      }
    })
    // return response.data.removeServiceType
    if (
      response.data.removeServiceType &&
      response.data.removeServiceType.status
    ) {
      return response.data.removeServiceType.result
    } else {
      console.error(response.data.removeServiceType.error)
      throw response.data.removeServiceType.error
    }
  }
}
