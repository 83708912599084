/* eslint-disable */

// import Roles from '@/security/roles';
// const roles = Roles.values;
import PagesAccess from '@/security/pages-access';
const pagesAccess = PagesAccess.values;

/**
 * List of Permissions and the Roles allowed of using them.
 */
class Permissions {
  static get values() {
    return {
      pagesAccessRead: pagesAccess.setAccessPermissions,
      adminRead: pagesAccess.viewAdmins,
      adminCreate: pagesAccess.createAdmin,
      adminEdit: pagesAccess.editAdmin,
      adminDestroy: pagesAccess.deleteAdmin,
      adminChangeStatus: pagesAccess.changeAdminStatus,
      adminPrint: pagesAccess.printAdminsTable,
      adminExport: pagesAccess.exportAdminsTable,

      userRead: pagesAccess.viewUsers,
      userDestroy: pagesAccess.deleteUser,
      userChangeStatus: pagesAccess.changeUserStatus,
      userPrint: pagesAccess.printUsersTable,
      userExport: pagesAccess.exportUsersTable,

      categoryRead: pagesAccess.viewCategories,
      categoryCreate: pagesAccess.createCategory,
      categoryEdit: pagesAccess.editCategory,
      categoryDestroy: pagesAccess.deleteCategory,
      categoryPrint: pagesAccess.printCategoriesTable,
      categoryExport: pagesAccess.exportCategoriesTable,

      subcategoryRead: pagesAccess.viewSubcategories,
      subcategoryCreate: pagesAccess.createSubcategory,
      subcategoryEdit: pagesAccess.editSubcategory,
      subcategoryDestroy: pagesAccess.deleteSubcategory,
      subcategoryPrint: pagesAccess.printSubcategoriesTable,
      subcategoryExport: pagesAccess.exportSubcategoriesTable,

      brandRead: pagesAccess.viewBrands,
      brandCreate: pagesAccess.createBrand,
      brandEdit: pagesAccess.editBrand,
      brandDestroy: pagesAccess.deleteBrand,
      brandPrint: pagesAccess.printBrandsTable,
      brandExport: pagesAccess.exportBrandsTable,

      productRead: pagesAccess.viewProducts,
      productEdit: pagesAccess.editProduct,
      productDestroy: pagesAccess.deleteProduct,

      orderRead: pagesAccess.viewOrders,
      orderEdit: pagesAccess.editOrder,
      orderTakeAction: pagesAccess.takeOrderAction,
      orderDestroy: pagesAccess.deleteOrder,

      requestRead: pagesAccess.viewRequests,
      requestEdit: pagesAccess.editRequest,
      requestTakeAction: pagesAccess.takeRequestAction,
      requestPrint: pagesAccess.printRequestsTable,
      requestExport: pagesAccess.exportRequestsTable,
      
      walletRead: pagesAccess.viewWallets,
      walletEdit: pagesAccess.editWallet,
      walletPrint: pagesAccess.printWalletsTable,
      walletExport: pagesAccess.exportWalletsTable,

      notificationRead: pagesAccess.viewNotifications,
      notificationCreate: pagesAccess.createNotification,
      notificationSend: pagesAccess.sendNotification,

      promoCodeRead: pagesAccess.viewPromoCodes,
      promoCodeCreate: pagesAccess.createPromoCode,
      promoCodeEdit: pagesAccess.editPromoCode,
      promoCode: pagesAccess.takePromoCodeAction,
      promoCodePrint: pagesAccess.printPromoCodesTable,
      promoCodeExport: pagesAccess.exportPromoCodesTable,

      sliderRead: pagesAccess.viewSliders,
      sliderCreate: pagesAccess.createSlider,
      sliderEdit: pagesAccess.editSlider,
      sliderDestroy: pagesAccess.deleteSlider,

      reportRead: pagesAccess.viewReports ,
      salesReportRead: pagesAccess.viewSalesReports,
      citiesReportRead: pagesAccess.viewCitiesReports,

      complaintRead: pagesAccess.viewComplaints,

      settingRead: pagesAccess.viewSettings,
      generalSettingRead: pagesAccess.viewGeneralSettings,
      generalSettingCreate: pagesAccess.createGeneralSettings,
      shippingSettingRead: pagesAccess.viewShippingSettings,
      shippingSettingCreate: pagesAccess.createShippingSettings,
      policyRead: pagesAccess.viewPolicySettings,
      policyCreate: pagesAccess.createPolicySetting,
    };
  }

  static get asArray() {
    return Object.keys(this.values).map(key => {
      return this.values[key];
    });
  }
}

export default Permissions;
