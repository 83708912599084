/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlFeedBack)

export class FeedbackService {
  // static async create(serviceTypeInput) {
  //   const response = await graphqlClient.mutate({
  //     mutation: gql`
  //       mutation CREATE_SERVICES_TYPE($serviceTypeInput: NameInput!) {
  //         createServiceType(serviceTypeInput: $serviceTypeInput)
  //       }
  //     `,

  //     variables: {
  //       serviceTypeInput
  //     }
  //   })

  //   if (
  //     response.data.createServiceType &&
  //     response.data.createServiceType.status
  //   ) {
  //     return response.data.createServiceType.result
  //   } else {
  //     console.error(response.data.createServiceType.error)
  //     throw response.data.createServiceType.error
  //   }
  // }
  static async find(feedBackId) {
    const response = await graphqlClient.query({
      query: gql`
        query FEEDBACK_FIND($feedBackId: String!) {
          findFeedBack(feedBackId: $feedBackId) {
            id
            clientId
            createdAt
            createdBy
            isRemoved
            stars
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        feedBackId
      }
    })

    return response.data.findFeedBack
  }

  static async list(pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query FEEDBACK_LIST($filter: [Filter]!, $pagination: PaginationInput!) {
          listFeedBacks(filter: $filter, pagination: $pagination) {
            id
            feedBack
            stars
            client {
              fullName
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        filter: [],
        pagination
      }
    })
    return response.data.listFeedBacks
  }
  // static async update(id, name) {
  //   const response = await graphqlClient.mutate({
  //     mutation: gql`
  //       mutation BRAND_UPDATE($brandId: String!, $updateObject: JSON!) {
  //         editBrand(brandId: $brandId, updateObject: $updateObject)
  //       }
  //     `,

  //     variables: {
  //       brandId: id,
  //       updateObject: { name }
  //     }
  //   })

  //   // return response.data.editBrand
  //   if (response.data.editBrand && response.data.editBrand.status) {
  //     return response.data.editBrand.result
  //   } else {
  //     console.error(response.data.editBrand.error);
  //     throw response.data.editBrand.error
  //   }
  // }

  // static async remove(brandId) {
  //   const response = await graphqlClient.mutate({
  //     mutation: gql`
  //       mutation BRAND_DESTROY($brandId: String!) {
  //         removeBrand(brandId: $brandId)
  //       }
  //     `,

  //     variables: {
  //       brandId
  //     }
  //   })
  //   // return response.data.removeBrand
  //   if (response.data.removeBrand && response.data.removeBrand.status) {
  //     return response.data.removeBrand.result
  //   } else {
  //     console.error(response.data.removeBrand.error);
  //     throw response.data.removeBrand.error
  //   }
  // }
}
