/* eslint-disable */
import { UserService } from '@/store/user/user-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'

export default {
  namespaced: true,

  state: {
    record: null,
    owner: null,
    loading: false,
    statusLoading: false,
    addresses: [],
    addressLoading: false
  },

  getters: {
    record: state => state.record,
    loading: state => !!state.loading,
    owner: state => state.owner,
    statusLoading: state => !!state.statusLoading,
    addresses: state => state.addresses || [],
    addressLoading: state => !!state.addressLoading
  },

  mutations: {
    CHANGE_STATUS_STARTED(state) {
      state.statusLoading = true
    },
    CHANGE_STATUS_SUCCESS(state) {
      state.statusLoading = false
    },
    CHANGE_STATUS_ERROR(state) {
      state.statusLoading = false
    },

    FIND_STARTED(state) {
      state.record = null
      state.loading = true
    },
    FIND_SUCCESS(state, payload) {
      state.record = payload
      state.loading = false
    },
    FIND_ERROR(state) {
      state.record = null
      state.loading = false
    },
    REMOVE_STARTED(state) {
      state.loading = true
    },
    REMOVE_SUCCESS(state) {
      state.loading = false
    },
    REMOVE_ERROR(state) {
      state.loading = false
    },
    FIND_ADDRESSES_STARTED(state) {
      state.addresses = []
      state.addressLoading = true
    },
    FIND_ADDRESSES_SUCCESS(state, payload) {
      state.addresses = payload
      state.addressLoading = false
    },
    FIND_ADDRESSES_ERROR(state) {
      state.addresses = []
      state.addressLoading = false
    }
  },

  actions: {
    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED')
        const record = await UserService.find(id)
        commit('FIND_SUCCESS', record)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ERROR')
        router.push('/users')
      }
    },
    async doRemoveOwned({ commit }, { clientId, unitId }) {
      try {
        commit('REMOVE_STARTED')
        const record = await UserService.removeOwnedUnit(clientId, unitId)
        commit('REMOVE_SUCCESS')
        Message.success(i18n('user.remove.success'))
        // router.back()
      } catch (error) {
        Errors.handle(error)
        commit('REMOVE_ERROR')
        // router.back()
      }
    },
    async doRemoveMembered({ commit }, { clientId, unitId }) {
      try {
        commit('REMOVE_STARTED')
        const record = await UserService.removeMemberedUnit(clientId, unitId)
        commit('REMOVE_SUCCESS')
        Message.success(i18n('user.remove.success'))
        // router.back()
      } catch (error) {
        Errors.handle(error)
        commit('REMOVE_ERROR')
        // router.back()
      }
    }
  }
}
