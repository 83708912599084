/* eslint-disable */
import { SliderService } from '@/store/slider/slider-service';
import Message from '@/shared/message/toastify';
import Errors from '@/shared/error/errors';
import { i18n } from '@/locales/i18n';

export default {
  namespaced: true,

  state: {
    loading: false,
  },

  getters: {
    loading: (state) => !!state.loading,
  },

  mutations: {
    DESTROY_ALL_STARTED(state) {
      state.loading = true;
    },
    DESTROY_ALL_SUCCESS(state) {
      state.loading = false;
    },
    DESTROY_ALL_ERROR(state) {
      state.loading = false;
    },

    DESTROY_STARTED(state) {
      state.loading = true;
    },
    DESTROY_SUCCESS(state) {
      state.loading = false;
    },
    DESTROY_ERROR(state) {
      state.loading = false;
    },
  },

  actions: {
    async doDestroy({ commit, dispatch }, id) {
      try {
        commit('DESTROY_STARTED');
        await SliderService.remove(id)
        commit('DESTROY_SUCCESS');
        Message.success(i18n('slider.destroy.success'))
        
        dispatch(`slider/list/doFetch`, {}, { root: true });
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ERROR');
      }
    },

    // async doDestroyAll({ commit, dispatch, rootGetters }, ids) {
    //   try {
    //     commit('DESTROY_ALL_STARTED');
  
    //     await SliderService.removeSliders(ids)

    //     commit('DESTROY_ALL_SUCCESS');
  
    //     // dispatch(`slider/list/doUnselectAll`, null, {
    //     //   root: true,
    //     // });
    //     rootGetters[`slider/list/type`] == 'slider'
    //       ? Message.success(i18n('slider.destroyAll.success'))
    //       : Message.success(i18n('slider.destroyAll.success'))

    //     dispatch(`slider/list/doFetch`, {
    //       filter: rootGetters[`slider/list/filter`], 
    //       pagination: rootGetters[`slider/list/pagination`], 
    //       type: rootGetters[`slider/list/type`]
    //     }, { root: true });
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('DESTROY_ALL_ERROR');
    //   }
    // },
  },
};
