/* eslint-disable */
import {
  collection,
  doc,
  query,
  where,
  orderBy,
  onSnapshot,
  getFirestore,
  serverTimestamp,
  setDoc,
  updateDoc
} from 'firebase/firestore'
import FirebaseRepository from '@/shared/firebase/firebase-repository'

const state = {
  complaints: [],
  messages: [],
  showChatBox: false,
  isTyping: false,
  typingList: [],
  complaintsFirestore: [],
  messagesFirestore: [],
  newComplaintsCount: 0
}

const getters = {
  complaints: state => state.complaints || [],
  messages: state => state.messages || [],
  showChatBox: state => state.showChatBox,
  isTyping: state => state.isTyping,
  typingList: state => state.typingList || [],
  complaintsFirestore: state => state.complaintsFirestore || [],
  messagesFirestore: state => state.messagesFirestore || [],
  newComplaintsCount: state => state.newComplaintsCount || 0
}

const mutations = {
  SET_NUMBER_OF_NEW_COMPLAINTS(state, payload) {
    state.newComplaintsCount = payload
  },
  SET_MESSAGE_CREATOR(state, payload) {
    let message = state.messages.filter(el => {
      el.id == payload.messageId
    })
    message.creator = payload.creator
  },
  SET_COMPLAINT_CREATOR(state, payload) {
    let complaint = state.complaints.filter(el => {
      el.id == payload.complaint
    })
    complaint.creator = payload.creator
  },
  APPEND_TO_MESSAGES(state, payload) {
    state.messages.push(payload)
  },
  APPEND_TO_COMPLAINTS(state, payload) {
    state.complaints.push(payload)
  },
  RESET_MESSAGES(state) {
    state.messages = []
    state.showChatBox = false
    state.isTyping = false
  },
  RESET_COMPLAINTS(state) {
    state.complaints = []
    state.showChatBox = false
    state.isTyping = false
  },
  FETCH_COMPLAINTS_SUCCESS(state, payload) {
    state.complaints = payload
  },
  FETCH_MESSAGES_SUCCESS(state, payload) {
    state.messages = payload
    state.showChatBox = true
  },
  UPDATE_TYPING_STATUS(state, payload) {
    state.isTyping = payload
  },
  UPDATE_TYPING_LIST(state, payload) {
    state.typingList = payload
  }
}

const actions = {
  resetComplaints({ commit }) {
    commit('RESET_COMPLAINTS')
  },
  resetMessages({ commit }) {
    commit('RESET_MESSAGES')
  },
  doRunListenerOnNewComplaints({ commit }) {
    try {
      const DB = getFirestore()
      const QUERY = query(
        collection(DB, 'complaints'),
        where('status', '==', 'pending')
      )
      onSnapshot(QUERY, querySnapshot => {
        commit('SET_NUMBER_OF_NEW_COMPLAINTS', querySnapshot.size)
      })
    } catch (error) {
      throw error
    }
  },
  doListenOnTyping({ commit }, complaintId) {
    const db = getFirestore()
    onSnapshot(doc(db, 'complaints', complaintId), doc => {
      const complaint = doc.data()
      commit('UPDATE_TYPING_STATUS', !!complaint.userTyping)
    })
  },
  async fetchComplaints({ commit, dispatch }, status) {
    try {
      commit('RESET_COMPLAINTS')

      const DB = getFirestore()
      const QUERY = query(
        collection(DB, 'complaints'),
        where('status', '==', status),
        orderBy('createdAt', 'desc')
      )
      onSnapshot(QUERY, querySnapshot => {
        const complaints = FirebaseRepository.mapCollection(querySnapshot)
        commit('FETCH_COMPLAINTS_SUCCESS', complaints)
        commit(
          'UPDATE_TYPING_LIST',
          complaints
            .filter(complaint => complaint.userTyping)
            .map(complaint => complaint.id)
        )
        dispatch('fetchComplaintSender')
      })
    } catch (error) {
      throw error
    }
  },
  async fetchMessages({ commit, dispatch, rootGetters }, complaintId) {
    try {
      commit('RESET_MESSAGES')
      const currentUser = rootGetters[`auth/currentUser`] || { id: null }
      const DB = getFirestore()
      const QUERY = query(
        collection(DB, `complaints/${complaintId}/messages`),
        orderBy('sentAt', 'asc')
      )
      onSnapshot(QUERY, querySnapshot => {
        const messages = FirebaseRepository.mapCollection(querySnapshot)
        commit('FETCH_MESSAGES_SUCCESS', messages)
        dispatch('doListenOnTyping', complaintId)
        const messagesIds = messages
          .filter(
            message => message.senderId != currentUser.id && !message.read
          )
          .map(message => message.id)
        dispatch('doSetIsReading', { complaintId, messagesIds })
      })
    } catch (error) {
      throw error
    }
  },
  fetchComplaintSender({ getters, commit }) {
    getters.complaints.forEach(async complaint => {
      if (!complaint.creator) {
        const creator = await FirebaseRepository.findDocument(
          'clients',
          complaint.createdBy
        )
        complaint.creator = creator || { id: complaint.createdBy }
        commit('SET_COMPLAINT_CREATOR', {
          complaint: complaint.id,
          creator: creator
        })
      }
    })
  },
  fetchMessageSender({ getters, commit }) {
    getters.messages.forEach(async message => {
      if (!message.creator) {
        const creator = await FirebaseRepository.findDocument(
          'clients',
          message.senderId
        )
        message.creator = creator || { id: message.senderId }
        commit('SET_MESSAGE_CREATOR', {
          messageId: message.id,
          creator: creator
        })
      }
    })
  },

  async doSendMessage({ rootGetters, dispatch }, payload) {
    const id = doc(collection(getFirestore(), 'ids')).id
    const currentUser = rootGetters[`auth/currentUser`] || { id: null }
    const message = {
      id: id,
      message: payload.message || null,
      attachment: payload.attachment || null,
      type: payload.type || null,
      read: false,
      senderId: currentUser.id,
      sentAt: serverTimestamp()
    }
    const DB = getFirestore()
    await setDoc(
      doc(DB, `complaints/${payload.complaintId}/messages/${id}`),
      message
    )
    await updateDoc(doc(DB, `complaints/${payload.complaintId}`), {
      status: 'open'
    })
    dispatch(
      `notification/form/doSendToClient`,
      {
        notification: payload.notification,
        clientId: payload.userId
      },
      { root: true }
    )
  },
  async closeComplaint({ commit }, payload) {
    const DB = getFirestore()
    await updateDoc(doc(DB, `complaints/${payload.id}`), {
      status: 'closed'
    })
    commit('RESET_MESSAGES')
  },
  doSetIsReading({ commit }, { complaintId, messagesIds }) {
    try {
      const DB = getFirestore()
      messagesIds.forEach(messageId => {
        updateDoc(doc(DB, `complaints/${complaintId}/messages/${messageId}`), {
          read: true
        })
      })
    } catch (error) {
      console.error(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
