import unitListStore from '@/store/unit/unit-list-store'
import unitFormStore from '@/store/unit/unit-form-store'
import unitViewStore from '@/store/unit/unit-view-store'

export default {
  namespaced: true,
  modules: {
    list: unitListStore,
    form: unitFormStore,
    view: unitViewStore
  }
}
