import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlLead)

export class LeadService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query LEAD_LIST($filter: [Filter]!, $pagination: PaginationInput!) {
          listLeads(filter: $filter, pagination: $pagination) {
            id
            jobTitle
            fullName
            phoneNumber
            projectId
            unitTemplate {
              id
              title {
                en
                ar
              }
              project {
                id
                name {
                  en
                  ar
                }
              }
            }
            createdBy
            createdAt
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        filter,
        pagination
      }
    })
    return response.data.listLeads
  }

  static async remove(leadId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation LEAD_DESTROY($leadId: String!) {
          removeLead(leadId: $leadId)
        }
      `,
      variables: {
        leadId
      }
    })
    if (response.data.removeLead && response.data.removeLead.status) {
      return response.data.removeLead.result
    } else {
      console.error(response.data.removeLead.error)
      throw response.data.removeLead.error
    }
  }
}
