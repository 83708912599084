/* eslint-disable */
import ServiceListStore from '@/store/service/service-list-store'
import serviceChatStore from '@/store/service/service-chat-store'

export default {
  namespaced: true,

  modules: {
    list: ServiceListStore,
    chat: serviceChatStore
  }
}
